import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";

const Plan = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [offers, setOffers] = useState([]);
    const [mostPopularOffer, setMostPopularOffer] = useState("");
    const [modalConditionsIsOpen, setModalConditionsIsOpen] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showGpsModal, setShowGpsModal] = useState(false);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);

    const handleRequestClick = () => {
        localStorage.removeItem("selectedOffer");
        setShowModal(true);
    };

    const handleFirstQuestionResponse = (response) => {
        if (response === 'yes') {
            navigate('/sheet');
        } else {
            setShowModal(false);
            setShowGpsModal(true);
        }
    };

    const handleGpsQuestionResponse = (response) => {
        if (response === 'yes') {
            navigate('/sheet');
        } else {
            setShowGpsModal(false);
            setShowThankYouMessage(true);
        }
    };

    const handleSelectOffer = (offer) => {
        const offerToStore = {
            nameOffer: offer.nameOffer,
            price: offer.price,
            speed: offer.speed,
            MinsFixes: offer.MinsFixes,
            MinsMobiles: offer.MinsMobiles,
            timestamp: new Date().getTime()
        };

        localStorage.setItem('selectedOffer', JSON.stringify(offerToStore));
        setModalConditionsIsOpen(true);
    };

    const closeConditions = () => {
        localStorage.removeItem('selectedOffer');
        setModalConditionsIsOpen(false);
    }

    const fetchOffers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/offer`);
            const offersData = response.data.offers;

            if (mostPopularOffer) {
                const updatedOffers = offersData.map((offer) => ({
                    ...offer,
                    isMostPop: offer.nameOffer === mostPopularOffer
                }));
                setOffers(updatedOffers);
            } else {
                setOffers(offersData);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des offres :", error);
        }
    };

    const fetchMostPopularOffer = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/offer/popular`);
            setMostPopularOffer(response.data.mostPopularOffer);
        } catch (error) {
            console.error("Erreur lors de la récupération de l'offre la plus populaire :", error);
        }
    };

    useEffect(() => {
        fetchMostPopularOffer();
    }, []);

    useEffect(() => {
        if (mostPopularOffer) {
            fetchOffers();
        }
    }, [mostPopularOffer]);

    return (
        <section className='py-14' id="plan">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className='relative max-w-xl mx-auto sm:text-center'>
                    <h3 className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                        {t('ourFttxOffers')}
                    </h3>
                    <div className='mt-3 max-w-xl'>
                        <p>
                            {t('enjoyFiber')}
                        </p>
                    </div>
                </div>
                <div className='w-full mt-7 space-y-3 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3'>
                    {
                        offers && offers.length > 0 ? (
                            offers.map((offer, idx) => (
                                <div key={idx} className='relative flex-1 flex items-stretch flex-col p-8 rounded-xl border-2 border-sky-500 text-center'>
                                    {
                                        offer.isMostPop ? (
                                            <span className="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 rounded-full border border-sky-500 shadow-md bg-white text-center text-gray-700 text-sm font-semibold">Most popular</span>
                                        ) : ""
                                    }
                                    <div>
                                        <span className='text-sky-600 font-medium text-3xl'>
                                            {offer.nameOffer}
                                        </span>
                                        <div className='mt-4 text-gray-800 text-3xl font-semibold'>
                                            {offer.price.toLocaleString()} FCFA <span className="text-xl text-gray-600 font-normal">/{t('month')}</span>
                                        </div>
                                    </div>
                                    <ul className='py-8 space-y-3'>
                                        <li className='flex items-center gap-5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-gauge text-sky-500">
                                                <path d="m12 14 4-4" />
                                                <path d="M3.34 19a10 10 0 1 1 17.32 0" />
                                            </svg>
                                            {t('maxSpeedUpTo')} {offer.speed} Mbps
                                        </li>
                                        <li className='flex items-center gap-5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-infinity text-sky-500">
                                                <path d="M12 12c-2-2.67-4-4-6-4a4 4 0 1 0 0 8c2 0 4-1.33 6-4Zm0 0c2 2.67 4 4 6 4a4 4 0 0 0 0-8c-2 0-4 1.33-6 4Z" />
                                            </svg>
                                            {t('unlimitedInternet')}
                                        </li>
                                        <li className='flex items-center gap-5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone-call text-sky-500">
                                                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /><path d="M14.05 2a9 9 0 0 1 8 7.94" />
                                                <path d="M14.05 6A5 5 0 0 1 18 10" />
                                            </svg>
                                            {offer.MinsFixes} {t('callMinsFixes')}
                                        </li>
                                        <li className='flex items-center gap-5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-smartphone-nfc text-sky-500">
                                                <rect width="7" height="12" x="2" y="6" rx="1" /><path d="M13 8.32a7.43 7.43 0 0 1 0 7.36" /><path d="M16.46 6.21a11.76 11.76 0 0 1 0 11.58" />
                                                <path d="M19.91 4.1a15.91 15.91 0 0 1 .01 15.8" />
                                            </svg>
                                            {offer.MinsMobiles} {t('callMinsMobiles')}
                                        </li>
                                    </ul>
                                    <div className="flex-1 flex items-end">

                                        <button
                                            onClick={() => handleSelectOffer(offer)}
                                            className='px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-sky-500 hover:bg-sky-600 active:bg-sky-700'
                                        >
                                            {t('selectBtn')}
                                        </button>

                                        <div className="bg-white rounded-md shadow-lg">
                                            <p>
                                                <Modal
                                                    isOpen={modalConditionsIsOpen}
                                                    onRequestClose={() => setModalConditionsIsOpen(false)}

                                                >
                                                    <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">{t('contractTitle')}</h2>
                                                    <div className="font-montserrat flex flex-col space-y-4">
                                                        <p className="text-justify text-xs">{t('contractText1')}</p>
                                                        <p className="text-justify text-xs">{t('contractText2')}</p>
                                                        <p className="text-justify text-xs">{t('contractText3')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article1Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article1Text1')}</p>
                                                        <p className="text-justify text-xs">{t('article1Text2')}</p>
                                                        <p className="text-justify text-xs">{t('article1Text3')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article2Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article2Text1')}</p>
                                                        <p className="text-justify text-xs">{t('article2Text2')}</p>
                                                        <p className="text-justify text-xs">{t('article2Text3')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article3Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article3Text1')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article4Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article4Text1')}</p>
                                                        <table className="table-auto w-full">
                                                            <tr>
                                                                <th>{t('article4TableTh1')}</th><th>{t('article4TableTh2')}</th><th>{t('article4TableTh3')}</th><th>{t('article4TableTh4')}</th><th>{t('article4TableTh5')}</th><th>{t('article4TableTh6')}</th>
                                                            </tr>
                                                            <tr><td className="border text-center">S</td><td className="border text-center">15 Mbps</td><td className="border text-center">{t('unlimited')}</td><td className="border text-center">200 mins</td><td className="border text-center">90 mins</td><td className="border text-center">25 000</td></tr>
                                                            <tr><td className="border text-center">M</td><td className="border text-center">25 Mbps</td><td className="border text-center">{t('unlimited')}</td><td className="border text-center">300 mins</td><td className="border text-center">120 mins</td><td className="border text-center">35 000</td></tr>
                                                            <tr><td className="border text-center">L</td><td className="border text-center">40 Mbps</td><td className="border text-center">{t('unlimited')}</td><td className="border text-center">400 mins</td><td className="border text-center">150 mins</td><td className="border text-center">50 000</td></tr>
                                                            <tr><td className="border text-center">XL</td><td className="border text-center">50 Mbps</td><td className="border text-center">{t('unlimited')}</td><td className="border text-center">500 mins</td><td className="border text-center">180 mins</td><td className="border text-center">60 000</td></tr>
                                                        </table>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article5Title')}</h1>
                                                        <p className="text-justify text-xs">
                                                            {t('article5Text1')}
                                                        </p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article6Title')}</h1>
                                                        <p className="text-justify text-xs">
                                                            {t('article6Text1')}
                                                            <ul className="list-disc ml-7">
                                                                <li>{t('article6Text1List1')}</li>
                                                                <li>{t('article6Text1List2')}</li>
                                                                <li>{t('article6Text1List3')}</li>
                                                                <li>{t('article6Text1List4')}</li>
                                                                <li>{t('article6Text1List5')}</li>
                                                            </ul>
                                                        </p>
                                                        <p className="text-justify text-xs">
                                                            {t('article6Text2')}
                                                            <ul className="list-disc ml-7">
                                                                <li>{t('article6Text2List1')}</li>
                                                                <li>{t('article6Text2List2')}</li>
                                                                <li>{t('article6Text2List3')}
                                                                    <ul>
                                                                        <li>{t('article6Text2List3Sub1')}</li>
                                                                        <li>{t('article6Text2List3Sub2')}</li>
                                                                        <li>{t('article6Text2List3Sub3')}</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article7Title')}</h1>
                                                        <p className="text-justify text-xs">
                                                            {t('article6Text1')}
                                                            <ul className="list-disc ml-7">
                                                                <li>{t('article7Text1List1')}</li>
                                                                <li>{t('article7Text1List2')}</li>
                                                                <li>{t('article7Text1List3')}</li>
                                                                <li>{t('article7Text1List4')}</li>
                                                            </ul>
                                                        </p>
                                                        <p className="text-justify text-xs">{t('article6Text2List1')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article8Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article8Text1')}</p>
                                                        <p className="text-justify text-xs">{t('article8Text2')}</p>
                                                        <p className="text-justify text-xs">{t('article8Text3')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article9Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article9Text1')}</p>
                                                        <p className="text-justify text-xs">{t('article9Text2')}</p>
                                                        <p className="text-justify text-xs">{t('article9Text3')}</p>
                                                        <p className="text-justify text-xs">
                                                            <ul className="list-disc ml-7">
                                                                <li>{t('article9Text3List1')}</li>
                                                                <li>{t('article9Text3List2')}</li>
                                                            </ul>
                                                        </p>
                                                        <p className="text-justify text-xs">{t('article9Text4')}</p>
                                                        <p className="text-justify text-xs">{t('article9Text5')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article10Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article10Text1')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article11Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article11Text1')}</p>
                                                        <p className="text-justify text-xs">
                                                            {t('article11Text2')}
                                                            <ul className="list-disc ml-7">
                                                                <li>{t('article11Text2List1')}</li>
                                                                <li>{t('article11Text2List2')}</li>
                                                                <li>{t('article11Text2List3')}</li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article12Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article12Text1')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article13Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article13Text1')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article14Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article14Text1')}</p>
                                                        <p className="text-justify text-xs">{t('article14Text2')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article15Title')}</h1>
                                                        <p className="text-justify text-xs font-bold">{t('article15Text1')}</p>
                                                        <p className="text-justify text-xs">{t('article15Text11')}</p>
                                                        <p className="text-justify text-xs">{t('article15Text12')}</p>
                                                        <p className="text-justify text-xs">{t('article15Text13')}</p>
                                                        <p className="text-justify text-xs font-bold">{t('article15Text2')}</p>
                                                        <p className="text-justify text-xs">
                                                            {t('article15Text2Text')}
                                                            <ul className="list-disc ml-7">
                                                                <li>{t('article15Text2List1')}</li>
                                                                <li>{t('article15Text2List2')}</li>
                                                                <li>{t('article15Text2List3')}</li>
                                                                <li>{t('article15Text2List4')}</li>
                                                            </ul>
                                                        </p>
                                                        <p className="text-justify text-xs font-bold">{t('article15Text3')}</p>
                                                        <p className="text-justify text-xs">{t('article15Text3Text')}</p>
                                                    </div>
                                                    <div className="font-montserrat my-3">
                                                        <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article16Title')}</h1>
                                                        <p className="text-justify text-xs">{t('article16Text')}</p>
                                                    </div>

                                                    <p>{t('byClikingNext')}</p>

                                                    <button
                                                        onClick={handleRequestClick}
                                                        className="font-montserrat bg-sky-500 hover:bg-sky-600 text-white px-7 py-2 float-right bold rounded-full ml-2"
                                                    >
                                                        {t('nextBtn')}
                                                    </button>

                                                    <button
                                                        onClick={closeConditions}
                                                        className="font-montserrat bg-gray-400 hover:bg-gray-500 text-white px-7 py-2 float-right bold rounded-full"
                                                    >
                                                        {t('refuseBtn')}
                                                    </button>
                                                </Modal>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center">Chargement des offres en cours...</p>
                        )
                    }
                </div>

                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                            <h2 className="text-lg font-bold mb-4 text-center">
                                {t('q1')}
                            </h2>
                            <div className="flex justify-center space-x-4">
                                <button
                                    className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                    onClick={() => handleFirstQuestionResponse('yes')}
                                >
                                    {t('yesResponse')}
                                </button>
                                <button
                                    className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                    onClick={() => handleFirstQuestionResponse('no')}
                                >
                                    {t('noResponse')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showGpsModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                            <h2 className="text-lg font-bold mb-4 text-center">
                                {t('q2')}
                            </h2>
                            <div className="flex justify-center space-x-4">
                                <button
                                    className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                    onClick={() => handleGpsQuestionResponse('yes')}
                                >
                                    {t('yesResponse')}
                                </button>
                                <button
                                    className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                    onClick={() => handleGpsQuestionResponse('no')}
                                >
                                    {t('noResponse')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showThankYouMessage && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                            <h2 className="text-lg font-bold mb-4 text-center text-gray-700">
                                {t('msg1')}
                            </h2>
                            <p className="text-center text-gray-600">
                                {t('msg2')}
                            </p>
                            <div className="mt-4 flex justify-center">
                                <button
                                    className="px-6 py-2 bg-sky-500 text-white rounded-lg hover:bg-sky-600"
                                    onClick={() => setShowThankYouMessage(false)}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div >
        </section >
    );
};

export default Plan;
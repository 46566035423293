import { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { useMap } from 'react-leaflet';
import customIconImage from '../assets/img/icons/map/epingle.png';

const customRoutingIcon = L.icon({
    iconUrl: customIconImage,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
});

const LeafletRoutingMachine = ({ start, end }) => {
    const map = useMap();

    useEffect(() => {
        L.Routing.control({
            waypoints: [
                L.latLng(start[0], start[1]),
                L.latLng(end[0], end[1]),
            ],
            createMarker: (i, waypoint) => {
                if (i === 1) {
                    return L.marker(waypoint.latLng, { icon: customRoutingIcon });
                }
                return null;
            },
            fitSelectedRoutes: false,
            lineOptions: {
                styles: [
                    {
                        color: 'blue',
                        weight: 4,
                        opacity: 0.5,
                    },
                ],
            },
            routeWhileDragging: false,
            show: false,
        }).addTo(map);
    }, [map, start, end]);

    return null;
};

export default LeafletRoutingMachine;
import React from 'react';
import { useTranslation } from 'react-i18next';

const ComingSoon = () => {
    const { t } = useTranslation();

    const closePage = () => {
        window.close();
    };

    return (
        <main>
            <div className="max-w-screen-xl mx-auto px-4 flex items-center justify-start h-screen md:px-8">
                <div className="max-w-lg mx-auto text-center">
                    <div className="pb-6">
                        <img
                            src={require('../assets/img/logo/fiberconnect-logo-no-bg.png')}
                            width={150}
                            className="mx-auto"
                            alt="FiberConnect Logo"
                        />
                    </div>
                    <h3 className="text-gray-800 text-4xl font-semibold sm:text-5xl">
                        {t('comingSoonTitle')}
                    </h3>
                    <p className="text-gray-600 mt-3">
                        {t('comingSoonText1')}
                    </p>
                    <div className="mt-6">
                        <img
                            src={require('../assets/img/icons/coming-soon.png')}
                            alt="Coming Soon"
                            className="mx-auto w-40"
                        />
                    </div>
                    <p className="text-gray-500 mt-6">
                        {t('comingSoonText2')}
                    </p>
                    <button
                        onClick={closePage}
                        className="mt-8 px-4 py-2 bg-sky-500 text-white rounded-lg hover:bg-sky-600 transition"
                    >
                        {t('comingSoonCloseBtn')}
                    </button>
                </div>
            </div>
        </main>
    );
};

export default ComingSoon;
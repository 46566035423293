import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvent } from 'react-leaflet';
import { Icon, L } from 'leaflet';
import AvatarEditor from 'react-avatar-editor';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "../apiConfig";
import logoCamtel from '../assets/img/icons/favicon/favicon-camtel.png';
import logosFtth from '../assets/img/logo/logos-ftth.png'
import Footer from "../components/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../config";
import Modal from 'react-modal';
import 'leaflet/dist/leaflet.css';
import LocationFinder from "../components/LocationFinder";
import SignatureCanvas from 'react-signature-canvas';
import WhatsAppButton from "../components/WhatsAppButton";
import '../style.css';
import { useTranslation } from 'react-i18next';
import AlertDanger from "../components/AlertDanger";

const Sheet = () => {
    const { t } = useTranslation();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [capVal, setCapVal] = useState(null);
    const [photoPreview, setPhotoPreview] = useState([]);
    const [photoLocationPreview, setPhotoLocationPreview] = useState([]);
    const [pdfLocationPreview, setPdfLocationPreview] = useState(false);
    const [pdfLocationFile, setPdfLocationFile] = useState([]);
    const [photoRectoCniPreview, setPhotoRectoCniPreview] = useState(null);
    const [photoVersoCniPreview, setPhotoVersoCniPreview] = useState(null);
    const [photoPassportPreview, setPhotoPassportPreview] = useState(null);
    const [photoResidencePreview, setPhotoResidencePreview] = useState(null);
    const [photoRegComPreview, setPhotoRegComPreview] = useState([]);
    const [pdfRegComPreview, setPdfRegComPreview] = useState(false);
    const [pdfRegComFile, setPdfRegComFile] = useState([]);

    const [signature, setSignature] = useState(null);
    const sigCanvas = useRef({});

    const saveSignature = () => {
        const customerSignature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setSignature(customerSignature);
        setFormData({ ...formData, signature: customerSignature });
    }

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFormData({ ...formData, signature: null });
        setSignature(null)
    }

    function formatDateToJJMMAA() {
        const date = new Date();
        const jour = date.getDate().toString().padStart(2, '0');
        const mois = (date.getMonth() + 1).toString().padStart(2, '0');
        const annee = date.getFullYear().toString().slice(-2);

        return jour + mois + annee;
    }

    const dateJJMMAA = formatDateToJJMMAA(new Date());

    // PHOTO DU CLIENT
    const [customerPhotoImage, setCustomerPhotoImage] = useState('');
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [canvas, setCanvas] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // RECTO & VERSO CNI
    const [rectoPhotoCniImage, setRectoPhotoCniImage] = useState('');
    const [canvasRectoCni, setCanvasRectoCni] = useState('');
    const [versoPhotoCniImage, setVersoPhotoCniImage] = useState('');
    const [scaleRectoCni, setScaleRectoCni] = useState(1);
    const [rotateRectoCni, setRotateRectoCni] = useState(0);
    const [scaleVersoCni, setScaleVersoCni] = useState(1);
    const [rotateVersoCni, setRotateVersoCni] = useState(0);
    const [canvasVersoCni, setCanvasVersoCni] = useState('');
    const [modalCniIsOpen, setModalCniIsOpen] = useState(false);

    // PASSPORT
    const [passportPhotoImage, setPassportPhotoImage] = useState('');
    const [scalePassport, setScalePassport] = useState(1);
    const [rotatePassport, setRotatePassport] = useState(0);
    const [canvasPassport, setCanvasPassport] = useState('');
    const [modalPassportIsOpen, setModalPassportIsOpen] = useState(false);

    // CARTE DE SEJOUR
    const [residencePhotoImage, setResidencePhotoImage] = useState('');
    const [scaleResidence, setScaleResidence] = useState(1);
    const [rotateResidence, setRotateResidence] = useState(0);
    const [canvasResidence, setCanvasResidence] = useState('');
    const [modalResidenceIsOpen, setModalResidenceIsOpen] = useState(false);

    // CONDITIONS
    const [modalConditionsIsOpen, setModalConditionsIsOpen] = useState(false);

    const editorRef = useRef();
    const editorRefRectoCni = useRef();
    const editorRefVersoCni = useRef();
    const editorRefPassport = useRef();
    const editorRefResidence = useRef();

    // CUSTOMER PHOTO
    const handleScaleChange = (e) => {
        setScale(parseFloat(e.target.value));
    }

    const handleRotateChange = (e) => {
        setRotate(parseFloat(e.target.value));
    }

    // CNI
    const handleScaleChangeRectoCni = (e) => {
        setScaleRectoCni(parseFloat(e.target.value));
    }

    const handleRotateChangeRectoCni = (e) => {
        setRotateRectoCni(parseFloat(e.target.value));
    }

    const handleScaleChangeVersoCni = (e) => {
        setScaleVersoCni(parseFloat(e.target.value));
    }

    const handleRotateChangeVersoCni = (e) => {
        setRotateVersoCni(parseFloat(e.target.value));
    }

    // PASSEPORT
    const handleScaleChangePassport = (e) => {
        setScalePassport(parseFloat(e.target.value));
    }

    const handleRotateChangePassport = (e) => {
        setRotatePassport(parseFloat(e.target.value));
    }

    // CARTE DE SEJOUR
    const handleScaleChangeResidence = (e) => {
        setScaleResidence(parseFloat(e.target.value));
    }

    const handleRotateChangeResidence = (e) => {
        setRotateResidence(parseFloat(e.target.value));
    }

    const previewHandle = () => {
        const canvasImage = editorRef.current.getImage();

        canvasImage.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoPreview(previewURL);

            const originalFileName = "profile_pic.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['customerPhoto']: originalFile,
            }));
        });
        setCanvas(canvasImage);

        setModalIsOpen(false);
    }

    const previewHandleCni = () => {

        const canvasImageRecto = editorRefRectoCni.current.getImage();
        const canvasImageVerso = editorRefVersoCni.current.getImage();

        canvasImageRecto.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoRectoCniPreview(previewURL);

            const originalFileName = "recto_cni.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['idCardFront']: originalFile,
            }));
        });
        setCanvasRectoCni(canvasImageRecto);

        canvasImageVerso.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoVersoCniPreview(previewURL);

            const originalFileName = "verso_cni.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['idCardBack']: originalFile,
            }));
        });
        setCanvasVersoCni(canvasImageVerso);

        setModalCniIsOpen(false);
    }

    const previewHandlePassport = () => {
        const canvasImagePassport = editorRefPassport.current.getImage();

        canvasImagePassport.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoPassportPreview(previewURL);

            const originalFileName = "passport_pic.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['idPasseportFront']: originalFile,
            }));
        });
        setCanvasPassport(canvasImagePassport);

        setModalPassportIsOpen(false);
    }

    const previewHandleResidence = () => {
        const canvasImageResidence = editorRefResidence.current.getImage();

        canvasImageResidence.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoResidencePreview(previewURL);

            const originalFileName = "residence_pic.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['idResidencePermitFront']: originalFile,
            }));
        });
        setCanvasResidence(canvasImageResidence);

        setModalResidenceIsOpen(false);
    }

    const acceptConditions = () => {
        setModalConditionsIsOpen(false);
    }

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    useEffect(() => {
        const getLocationCoords = async () => {
            setLoading(true);

            navigator.geolocation.getCurrentPosition(async position => {
                const { latitude, longitude } = position.coords;

                if (!latitude || !longitude) {
                    console.error("Coordonnées de géolocalisation invalides");
                    return;
                }

                setLatitude(latitude);
                setLongitude(longitude);

                const regionAbbreviations = {
                    'Extrême-Nord': 'EN',
                    'Nord': 'NO',
                    'Adamaoua': 'AD',
                    'Centre': 'CE',
                    'Littoral': 'LT',
                    'Sud': 'SU',
                    'Est': 'ES',
                    'Ouest': 'OU',
                    'Nord-Ouest': 'NW',
                    'Sud-Ouest': 'SW'
                };

                try {
                    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
                    const data = response.data;

                    const road = data.address.road;
                    const neighbourhood = data.address.suburb;
                    const city = data.address.city;
                    let region = data.address.state;

                    localStorage.setItem('city', city);
                    localStorage.setItem('preciseLocation', `${neighbourhood}, ${road}`);

                    const match = region.match(/Région de l'(.*)|Région du (.*)/);
                    if (match) {
                        region = match[1] || match[2];
                    }

                    const regionAbbreviation = regionAbbreviations[region];

                    setFormData({
                        ...formData,
                        region: regionAbbreviation,
                        customerGpsCoordinates: latitude + ',' + longitude,
                        city: localStorage.getItem('city'),
                        preciseLocation: localStorage.getItem('preciseLocation')
                    });

                } catch (error) {
                    console.error(error);
                    alert(t('errorMsgApiCoords'));
                }

                setLoading(false);
            },
                error => {
                    console.error("Erreur lors de la récupération de la position:", error);
                    alert(t('errorMsgApiCoords'));
                    setLoading(false);
                }
            );
        }

        getLocationCoords();
    }, []);

    const handleMapClick = async (latlng) => {
        if (!latlng || !latlng.lat || !latlng.lng) {
            console.error("Coordonnées invalides:", latlng);
            return;
        }

        setLatitude(latlng.lat);
        setLongitude(latlng.lng);

        const regionAbbreviations = {
            'Extrême-Nord': 'EN',
            'Nord': 'NO',
            'Adamaoua': 'AD',
            'Centre': 'CE',
            'Littoral': 'LT',
            'Sud': 'SU',
            'Est': 'ES',
            'Ouest': 'OU',
            'Nord-Ouest': 'NW',
            'Sud-Ouest': 'SW'
        };

        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latlng.lat}&lon=${latlng.lng}`);
            const data = response.data;
            const road = data.address.road;
            const neighbourhood = data.address.suburb;
            const city = data.address.city;
            let region = data.address.state;

            const match = region.match(/Région de l'(.*)|Région du (.*)/);
            if (match) {
                region = match[1] || match[2];
            }

            const regionAbbreviation = regionAbbreviations[region];

            setFormData({
                ...formData,
                region: regionAbbreviation,
                city: city,
                preciseLocation: neighbourhood + ', ' + road,
                customerGpsCoordinates: latlng.lat + ',' + latlng.lng
            });
        } catch (error) {
            console.error(error);
        }
    }

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const loadSelectedOffer = () => {
            const storedOffer = localStorage.getItem('selectedOffer');
            if (storedOffer) {
                try {
                    const parsedOffer = JSON.parse(storedOffer);

                    setSelectedPlan(parsedOffer.nameOffer);
                    setFormData(prev => ({
                        ...prev,
                        offerBlueMaxSelected: parsedOffer.nameOffer
                    }));
                } catch (error) {
                    console.error("Erreur lors du chargement de l'offre:", error);
                }
            }
        };

        loadSelectedOffer();
    }, []);

    const [formData, setFormData] = useState({
        idEntity: 0,
        nameEntity: '',
        idPartnerCommercial: 0,
        customerPhoto: null,
        idSubscription: dateJJMMAA + "-",
        nameOrSocialName: "",
        customerType: "Particulier",
        region: "",
        city: localStorage.getItem('city'),
        preciseLocation: localStorage.getItem('preciseLocation'),
        preciseLocationFile: null,
        lieuDit: "",
        customerGpsCoordinates: latitude + ',' + longitude,
        email: "",
        mobile: "",
        idDoc: "CNI",
        idCardNumber: "",
        idCardFront: null,
        idCardBack: null,
        idPassportNumber: "",
        idResidencePermitNumber: "",
        idPasseportFront: null,
        idResidencePermitFront: null,
        regComNumber: "",
        commerceRegistryFront: null,
        offerBlueMaxSelected: '',
        isAutoSubscribe: 1,
        isSuperAdmin: 0,
        idSuperAdmin: 0,
        signature: ""
    });

    const [formErrors, setFormErrors] = useState({});
    const [globalError, setGlobalError] = useState(null);

    const [steps, setStep] = useState({
        stepsItems: [localStorage.getItem('language') === 'en' ? 'Customer Information' : 'Informations du client', localStorage.getItem('selectedOffer') ? (localStorage.getItem('language') === 'en' ? 'Customer Signature' : 'Signature du client') : (localStorage.getItem('language') === 'en' ? 'Choice of offer' : 'Choix de l\'offre'), localStorage.getItem('language') === 'en' ? 'Summary' : 'Récapitulatif'],
        currentStep: 1,
        formData: {
            idEntity: 0,
            nameEntity: '',
            idPartnerCommercial: 0,
            customerPhoto: null,
            idSubscription: dateJJMMAA + "-",
            nameOrSocialName: "",
            customerType: "Particulier",
            region: "",
            city: "",
            preciseLocation: "",
            preciseLocationFile: null,
            lieuDit: "",
            customerGpsCoordinates: latitude + ',' + longitude,
            email: "",
            mobile: "",
            idDoc: "CNI",
            idCardNumber: "",
            idCardFront: null,
            idCardBack: null,
            idPassportNumber: "",
            idResidencePermitNumber: "",
            idPasseportFront: null,
            idResidencePermitFront: null,
            regComNumber: "",
            commerceRegistryFront: null,
            offerBlueMaxSelected: '',
            isAutoSubscribe: 1,
            isSuperAdmin: 0,
            idSuperAdmin: 0,
            signature: ""
        },
    });

    const regionCoordinates = {
        'EN': { latitude: '10.6033083', longitude: '14.3281625' },
        'NO': { latitude: '9.3070698', longitude: '13.3934527' },
        'AD': { latitude: '7.3211536', longitude: '7.3211536' },
        'CE': { latitude: '3.8689867', longitude: '11.5213344' },
        'LT': { latitude: '4.0429408', longitude: '9.706203' },
        'SU': { latitude: '2.919384', longitude: '11.151663' },
        'ES': { latitude: '4.5776155', longitude: '13.6843792' },
        'OU': { latitude: '5.4758844', longitude: '10.4217852' },
        'NW': { latitude: '5.9614117', longitude: '10.1516505' },
        'SW': { latitude: '4.1567895', longitude: '9.2315915' },
    };

    const handleSelectChange = async (e) => {
        const newValue = e.target.value;
        setSelectedOption(newValue);

        const coordinates = regionCoordinates[newValue];
        if (!coordinates || !coordinates.latitude || !coordinates.longitude) {
            console.error("Coordonnées non disponibles ou invalides pour cette région");
            return;
        }

        setLatitude(coordinates.latitude);
        setLongitude(coordinates.longitude);

        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinates.latitude}&lon=${coordinates.longitude}`);
            const data = response.data;
            const road = data.address.road;
            const neighbourhood = data.address.suburb;
            const city = data.address.city;

            setFormData({ ...formData, region: newValue, city: city, preciseLocation: neighbourhood + ', ' + road, customerGpsCoordinates: coordinates.latitude + ',' + coordinates.longitude });
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        setStep((prevState) => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));

        if (name === 'customerGpsCoordinates') {
            const coordinates = value.split(',').map(coord => parseFloat(coord.trim()));

            if (coordinates.length === 2 && !isNaN(coordinates[0]) && !isNaN(coordinates[1])) {
                setLatitude(coordinates[0]);
                setLongitude(coordinates[1]);

                navigator.geolocation.getCurrentPosition(() => {
                    const regionAbbreviations = {
                        'Extrême-Nord': 'EN',
                        'Nord': 'NO',
                        'Adamaoua': 'AD',
                        'Centre': 'CE',
                        'Littoral': 'LT',
                        'Sud': 'SU',
                        'Est': 'ES',
                        'Ouest': 'OU',
                        'Nord-Ouest': 'NW',
                        'Sud-Ouest': 'SW'
                    };

                    axios.get(`https://nominatim.openstreetmap.org/reverse`, {
                        params: {
                            format: 'json',
                            lat: coordinates[0],
                            lon: coordinates[1]
                        }
                    })
                        .then(({ data }) => {
                            const road = data.address.road;
                            const neighbourhood = data.address.suburb;
                            const city = data.address.city;
                            let region = data.address.state;

                            const match = region.match(/Région de l'(.*)|Région du (.*)/);
                            if (match) {
                                region = match[1] || match[2];
                            }

                            const regionAbbreviation = regionAbbreviations[region];

                            setFormData({
                                ...formData,
                                region: regionAbbreviation,
                                city: city,
                                preciseLocation: `${neighbourhood}, ${road}`,
                                customerGpsCoordinates: `${coordinates[0]},${coordinates[1]}`
                            });
                        })
                        .catch(error => console.error("Erreur lors de la récupération des données :", error));
                });
            } else {
                console.error("Coordonnées invalides:", value);
            }
        }

        if (formData.idDoc === 'CNI') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                idPassportNumber: '',
                idResidencePermitNumber: '',
                idPasseportFront: null,
                idResidencePermitFront: null,
                idResidencePermitFront: null
            }));
        } else if (formData.idDoc === 'Passeport') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                idCardNumber: '',
                idResidencePermitNumber: '',
                idCardFront: null,
                idCardBack: null,
                idResidencePermitFront: null
            }));
        } else {
            if (formData.idDoc === 'Carte de séjour') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    idCardNumber: '',
                    idPassportNumber: '',
                    idCardFront: null,
                    idCardBack: null,
                    idPasseportFront: null,
                }));
            }
        }
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setStep((prevState) => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: files[0],
            },
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));

        if (name === 'preciseLocationFile' && files.length > 0) {
            const file = files[0];
            const fileType = file.type;

            if (fileType.startsWith('image/')) {
                setPhotoLocationPreview(URL.createObjectURL(file));
            } else if (fileType === 'application/pdf') {
                setPdfLocationPreview(true);
                setPdfLocationFile(URL.createObjectURL(file));
            } else {
                console.error('Type de fichier non pris en charge.');
            }
        }

        if (name === 'idCardFront' && files.length > 0) {
            setPhotoRectoCniPreview(URL.createObjectURL(files[0]));
        }

        if (name === 'idCardBack' && files.length > 0) {
            setPhotoVersoCniPreview(URL.createObjectURL(files[0]));
        }

        if (name === 'idPasseportFront' && files.length > 0) {
            setPhotoPassportPreview(URL.createObjectURL(files[0]));
        }

        if (name === 'idResidencePermitFront' && files.length > 0) {
            setPhotoResidencePreview(URL.createObjectURL(files[0]));
        }

        if (name === 'commerceRegistryFront' && files.length > 0) {
            const file = files[0];
            const fileType = file.type;

            if (fileType.startsWith('image/')) {
                setPhotoRegComPreview(URL.createObjectURL(file));
            } else if (fileType === 'application/pdf') {
                setPdfRegComPreview(true);
                setPdfRegComFile(URL.createObjectURL(file));
            } else {
                console.error('Type de fichier non pris en charge.');
            }
        }
    };

    const handleSelectPlan = (planName) => {
        setSelectedPlan(planName);

        setFormData({ ...formData, offerBlueMaxSelected: planName });
    };

    const isValidGpsFormat = (gpsString) => {
        const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
        return regex.test(gpsString);
    };

    const nextStep = () => {
        const currentStepFields = getRequiredFieldsForStep(steps.currentStep);
        const errors = {};

        currentStepFields.forEach((fieldName) => {
            if (!steps.formData[fieldName]) {
                errors[fieldName] = t('errorMsgRequiredField');
            }
        });

        if (steps.currentStep === 1 && !formData.city) {
            errors.city = t('erorMsgCity');
        }

        if (steps.currentStep === 1 && !formData.preciseLocation) {
            errors.preciseLocation = t('errorMsgNeighborhood');
        }

        if (steps.currentStep === 1) {
            if (!formData.customerGpsCoordinates || !isValidGpsFormat(formData.customerGpsCoordinates)) {
                errors.customerGpsCoordinates = t('errorMsgCoords');
            }
        }

        if (steps.currentStep === 1 && !canvas) {
            errors.customerPhoto = t('errorMsgCustomerPic');
        }

        if (steps.currentStep === 1 && !steps.formData.preciseLocationFile) {
            errors.preciseLocationFile = t('errorMsgImgLocation');
        }

        if (steps.formData.customerType === "Entreprise") {
            if (!steps.formData.regComNumber) {
                errors.regComNumber = t('errorMsgRegComNumber');
            }

            if (!steps.formData.commerceRegistryFront) {
                errors.commerceRegistryFront = t('errorMsgRegComFiles');
            }
        }

        if (steps.currentStep === 1 && steps.formData.idDoc === "CNI") {
            if (!canvasRectoCni && !canvasVersoCni) {
                errors.idCardFront = t('errorMsgCniFiles');
            }
        } else if (steps.currentStep === 1 && steps.formData.idDoc === "Passeport") {
            if (steps.currentStep === 1 && !canvasPassport) {
                errors.idPasseportFront = t('errorMsgPassportFiles');
            }
        } else {
            if (steps.currentStep === 1 && !canvasResidence) {
                errors.idResidencePermitFront = t('errorMsgResidentFiles');
            }
        }

        if (steps.formData.idDoc === "CNI") {
            if (!steps.formData.idCardNumber) {
                errors.idCardNumber = t('errorMsgCniNumber');
            }
        } else if (steps.formData.idDoc === "Passeport") {
            if (!steps.formData.idPassportNumber) {
                errors.idPassportNumber = t('errorMsgPassportNumber');
            }
        } else {
            if (steps.formData.idDoc === "Carte de séjour") {
                if (!steps.formData.idResidencePermitNumber) {
                    errors.idResidencePermitNumber = t('errorMsgResidentNumber');
                }
            }
        }

        if (Object.keys(errors).length === 0) {
            setFormErrors({});
            setGlobalError(null);

            if (steps.currentStep < steps.stepsItems.length) {
                setStep((prevState) => ({
                    ...prevState,
                    currentStep: prevState.currentStep + 1,
                }));
            }
        } else {
            setFormErrors(errors);
            setGlobalError(t('errorDanger'));
        }
    };

    const getRequiredFieldsForStep = (step) => {
        const commonFields = ["nameOrSocialName", "customerType", "lieuDit", "email", "mobile"];
        const enterpriseFields = ["regComNumber"];

        switch (step) {
            case 1:
                return steps.formData.customerType === "Entreprise" ? [...commonFields, ...enterpriseFields] : commonFields;
            case 2:
                return [];
            default:
                return [];
        }
    };

    const prevStep = () => {
        if (steps.currentStep > 1) {
            setStep((prevState) => ({
                ...prevState,
                currentStep: prevState.currentStep - 1,
            }));
        }
    };

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);

        const updatedFormData = {
            ...formData,
            offerBlueMaxSelected: selectedPlan,
        };

        try {
            const response = await axios.post(`${BASE_URL}/api/orders`, updatedFormData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            const { insertedId } = response.data;

            localStorage.setItem('idSheet', insertedId);

            navigate(`/location/${insertedId}`);
        } catch (error) {
            console.error('Une erreur s\'est produite lors de l\'envoi de la demande :', error);
            alert('Erreur lors de votre souscription. Veuillez vous assurer que toutes les informations obligatoires sont renseignées dans les deux étapes.');
        }
    };

    const [plans, setPlans] = useState(null);

    const fetchOffers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/offer`);
            const offersData = response.data.offers;
            setPlans(offersData);
        } catch (error) {
            console.error("Erreur lors de la récupération des offres :", error);
        }
    };

    useEffect(() => {
        fetchOffers();
    }, []);

    const customHomeIcon = new Icon({
        iconUrl: require('../assets/img/icons/map/location.png'),
        iconSize: [38, 38]
    });

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../assets/img/icons/gif/loading-points.webp')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    {isButtonDisabled ? (
                        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 flex">
                            <h3 className="text-gray-800 text-5xl font-bold sm:text-5xl text-center text-sky-500 fade-in-out">
                                Test d'éligibilité en cours. <br />
                                Veuillez patientez s'il vous plait...
                            </h3>
                        </div>
                    ) : (
                        <>
                            <div
                                className="flex items-center justify-center bg-cover bg-center bg-no-repeat"
                                style={{
                                    backgroundImage: `url(${require('../assets/img/banner/banner.jpeg')})`,
                                    height: '250px',
                                }}
                            >
                                <div className="">
                                    <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl text-white">
                                        {t('subscribeForm')}
                                    </h3>
                                </div>
                            </div>

                            <div className="w-2xl mx-auto px-4 mt-10 md:px-0 mb-10 border border-gray-300 rounded-md" style={{ width: '90%' }}>
                                <AlertDanger
                                    message={globalError}
                                    onClose={() => setGlobalError(null)}
                                />

                                <form onSubmit={handleSubmit} enctype="multipart/form-data" className="p-10">
                                    <ul aria-label="Steps" className="items-center text-gray-600 font-medium md:flex">
                                        {steps.stepsItems.map((item, idx) => (
                                            <li
                                                key={item}
                                                aria-current={steps.currentStep === idx + 1 ? "step" : false}
                                                className="flex gap-x-3 md:flex-col md:flex-1 md:gap-x-0"
                                            >
                                                <div className="flex flex-col items-center md:flex-row md:flex-1">
                                                    <hr
                                                        className={`w-full border hidden md:block ${idx === 0 ? "border-none" : steps.currentStep >= idx + 1 ? "border-sky-500" : ""
                                                            }`}
                                                    />
                                                    <div
                                                        className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${steps.currentStep > idx + 1 ? "bg-sky-500 border-sky-500" : "" ||
                                                            steps.currentStep === idx + 1 ? "border-sky-500" : ""
                                                            }`}
                                                    >
                                                        <span className={`w-2.5 h-2.5 rounded-full bg-sky-500 ${steps.currentStep !== idx + 1 ? "hidden" : ""}`}></span>
                                                        {steps.currentStep > idx + 1 ? (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor"
                                                                className="w-5 h-5 text-white"
                                                            >
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                            </svg>
                                                        ) : ""}
                                                    </div>
                                                    <hr
                                                        className={`h-12 border md:w-full md:h-auto ${idx + 1 === steps.stepsItems.length ? "border-none" : "" ||
                                                            steps.currentStep > idx + 1 ? "border-sky-500" : ""}`}
                                                    />
                                                </div>
                                                <div className="h-8 flex justify-center items-center md:mt-3 md:h-auto">
                                                    <h3 className={`text-sm ${steps.currentStep === idx + 1 ? "text-sky-500" : ""}`}>
                                                        {item}
                                                    </h3>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="mt-4">
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`} style={{ zIndex: 999 }}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                {t('customerPhoto')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>

                                            <button
                                                type="button"
                                                onClick={() => setModalIsOpen(true)}
                                                className="bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded-full"
                                            >
                                                {t('selectPhoto')}
                                            </button>

                                            <Modal
                                                isOpen={modalIsOpen}
                                                onRequestClose={() => setModalIsOpen(false)}
                                                contentLabel="File Upload Modal"
                                            >
                                                <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">{t('uploadCustomerPicTitle')}</h2>
                                                <div className="font-montserrat flex flex-col items-center space-y-4">
                                                    {customerPhotoImage ? <AvatarEditor
                                                        ref={editorRef}
                                                        image={customerPhotoImage}
                                                        width={250}
                                                        height={250}
                                                        border={50}
                                                        borderRadius={500}
                                                        color={[135, 206, 250, 0.6]}
                                                        scale={scale}
                                                        rotate={rotate}
                                                    /> : ''}

                                                    {customerPhotoImage && (
                                                        <div className='flex flex-col items-center space-y-2'>
                                                            <div className='flex items-center space-y-2'>
                                                                <div className="mx-2">
                                                                    <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="range"
                                                                        id="scale"
                                                                        min="0.1"
                                                                        max="10"
                                                                        step="0.1"
                                                                        value={scale}
                                                                        onChange={handleScaleChange}
                                                                        className="w-48"
                                                                    />
                                                                    <span className="text-gray-500 font-bold mx-1">{scale}</span>
                                                                </div>
                                                            </div>

                                                            <div className="flex items-center space-y-2">
                                                                <div className="mx-2">
                                                                    <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="range"
                                                                        id="rotate"
                                                                        min="0"
                                                                        max="360"
                                                                        step="1"
                                                                        value={rotate}
                                                                        onChange={handleRotateChange}
                                                                        className="w-48"
                                                                    />
                                                                    <span className="text-gray-500 font-bold mx-1">{rotate}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <input
                                                        type='file'
                                                        accept="image/jpeg, image/jpg, image/png"
                                                        name="customerPhoto"
                                                        onChange={e => {
                                                            setCustomerPhotoImage(e.target.files[0]);
                                                        }}
                                                        className="block py-2 px-4 w-full text-sm text-slate-500
                                                        file:mr-4 file:py-2 file:px-4
                                                        file:rounded-full file:border-0
                                                        file:text-sm file:font-semibold
                                                        file:bg-sky-50 file:text-sky-700
                                                        hover:file:bg-sky-100"
                                                    />
                                                    {customerPhotoImage && (
                                                        <button
                                                            type="button"
                                                            onClick={previewHandle}
                                                            className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full"
                                                        >
                                                            Valider
                                                        </button>
                                                    )}

                                                    {canvas ? <canvas src={canvas} /> : ''}
                                                </div>
                                            </Modal>

                                            {formErrors.customerPhoto && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.customerPhoto}</p>
                                            )}
                                        </div>
                                        
                                        {/* Noms et Prénoms / Raison sociale */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nameOrSocialName">
                                                {t('nameOrSocialName')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="nameOrSocialName"
                                                type="text"
                                                placeholder={t('nameOrSocialName')}
                                                name="nameOrSocialName"
                                                value={steps.formData.nameOrSocialName}
                                                onChange={handleChange}
                                            />
                                            {formErrors.nameOrSocialName && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.nameOrSocialName}</p>
                                            )}
                                        </div>
                                    </div>

                                    {/* Type de client (Particulier ou entreprise) */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            {t('customerType')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <div className="flex">
                                            <label className="mr-4">
                                                <input
                                                    type="radio"
                                                    name="customerType"
                                                    value="Particulier"
                                                    checked={steps.formData.customerType === "Particulier"}
                                                    onChange={handleChange}
                                                />
                                                <span className="ml-2">{t('customerType1')}</span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="customerType"
                                                    value="Entreprise"
                                                    checked={steps.formData.customerType === "Entreprise"}
                                                    onChange={handleChange}
                                                />
                                                <span className="ml-2">{t('customerType2')}</span>
                                            </label>
                                        </div>
                                        {formErrors.customerType && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.customerType}</p>
                                        )}
                                    </div>

                                    {/* Région */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            {t('region')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <select
                                            id="region"
                                            name="region"
                                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            value={formData.region ? formData.region : selectedOption}
                                            onChange={handleSelectChange}
                                        >
                                            <option value="">{t('regionChoice')}</option>
                                            <option value="EN">{t('en')}</option>
                                            <option value="NO">{t('no')}</option>
                                            <option value="AD">{t('ad')}</option>
                                            <option value="CE">{t('ce')}</option>
                                            <option value="LT">{t('lt')}</option>
                                            <option value="OU">{t('ou')}</option>
                                            <option value="SU">{t('su')}</option>
                                            <option value="NW">{t('nw')}</option>
                                            <option value="SW">{t('sw')}</option>
                                            <option value="ES">{t('es')}</option>
                                        </select>
                                        {formErrors.region && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.region}</p>
                                        )}
                                    </div>

                                    {/* Ville */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            {t('city')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            className="block appearance-none w-full border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            name="city"
                                            placeholder={t('city')}
                                            value={formData.city}
                                            onChange={handleChange}
                                        />
                                        {formErrors.city && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.city}</p>
                                        )}
                                    </div>

                                    {/* Localisation précise */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preciseLocation">
                                            {t('neighborhood')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="preciseLocation"
                                            type="text"
                                            placeholder={t('neighborhood')}
                                            name="preciseLocation"
                                            value={formData.preciseLocation}
                                            onChange={handleChange}
                                        />
                                        {formErrors.preciseLocation && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.preciseLocation}</p>
                                        )}
                                    </div>


                                    {/* Plan de localisation */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>

                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            {t('locationPlanImg')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>

                                        <div className="flex items-center justify-center w-full mb-4">
                                            <label
                                                htmlFor="dropzone-file"
                                                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                                            >
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <svg className="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5A5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                        />
                                                    </svg>
                                                    <p className="mb-2 text-sm text-gray-500">
                                                        <span className="font-semibold">{t('locationPlanClickToUpload')}</span>{t('locationPlanOrDragAndDrop')}
                                                    </p>
                                                    <p className="text-xs text-gray-500">{t('locationPlanImgOrPdf')}</p>
                                                </div>
                                                <input
                                                    id="dropzone-file"
                                                    type="file"
                                                    accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                    name="preciseLocationFile"
                                                    onChange={handleFileChange}
                                                    className="hidden"
                                                />
                                            </label>
                                        </div>

                                        {photoLocationPreview && (
                                            <div className="h-full">
                                                {pdfLocationPreview ? (
                                                    <iframe
                                                        src={pdfLocationFile}
                                                        className="h-screen w-full"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                ) : (
                                                    <img
                                                        src={photoLocationPreview}
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 'auto', width: '100%' }}
                                                    />
                                                )}
                                            </div>
                                        )}

                                        {/* <label className="block text-gray-700 text-sm font-bold mb-2">
                                            {t('locationPlanImg')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label> */}
                                        {/* <input
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png, application/pdf"
                                            name="preciseLocationFile"
                                            onChange={handleFileChange}
                                            className="block w-full text-sm text-slate-500
                                            file:mr-4 file:py-2 file:px-4
                                            file:rounded-full file:border-0
                                            file:text-sm file:font-semibold
                                            file:bg-sky-50 file:text-sky-700
                                            hover:file:bg-sky-100 cursor-pointer"
                                        /> */}
                                        {formErrors.preciseLocationFile && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.preciseLocationFile}</p>
                                        )}


                                    </div>

                                    {/* Lieu-dit */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lieuDit">
                                            {t('lieuDit')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="lieuDit"
                                            type="text"
                                            placeholder={t('lieuDit')}
                                            name="lieuDit"
                                            value={steps.formData.lieuDit}
                                            onChange={handleChange}
                                        />
                                        {formErrors.lieuDit && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.lieuDit}</p>
                                        )}
                                    </div>

                                    {/* Coordonnées GPS du client */}
                                    <div className={`relative mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerGpsCoordinates">
                                            {t('customerGpsCoords')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="customerGpsCoordinates"
                                            type="text"
                                            placeholder={t('customerGpsCoords')}
                                            name="customerGpsCoordinates"
                                            value={
                                                formData.customerGpsCoordinates !== null && formData.customerGpsCoordinates !== undefined
                                                    ? formData.customerGpsCoordinates
                                                    : (steps.formData.customerGpsCoordinates || '')
                                            }
                                            onChange={handleChange}
                                        />
                                        <div
                                            title={t('clearCustomerGpsCoords')}
                                            onClick={() => {
                                                setFormData({
                                                    ...formData,
                                                    customerGpsCoordinates: '',
                                                    preciseLocation: ''
                                                });
                                            }}
                                            className="absolute right-3 top-9 focus:outline-none cursor-pointer"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                                        </div>
                                        {formErrors.customerGpsCoordinates && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.customerGpsCoordinates}</p>
                                        )}
                                    </div>

                                    {latitude && longitude && (
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`} style={{ zIndex: 0 }}>
                                            <p className="mb-2" style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>NB:</span> {t('nb')}</p>
                                            <MapContainer
                                                key={[latitude, longitude]}
                                                center={[latitude, longitude]}
                                                zoom={17}
                                                style={{ height: 350, width: '100%', zIndex: 0 }}
                                            >
                                                <LocationFinder onMapClick={handleMapClick} />
                                                <TileLayer
                                                    attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker position={[latitude, longitude]} icon={customHomeIcon}>
                                                    <Popup>
                                                        <h2>Mon Domicile</h2>
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    )}

                                    {/* Adresse mail */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                            {t('emailAdress')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email"
                                            type="email"
                                            placeholder={t('emailAdress')}
                                            name="email"
                                            value={steps.formData.email}
                                            onChange={handleChange}
                                        />
                                        {formErrors.email && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                                        )}
                                    </div>

                                    {/* N° mobile */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobile">
                                            {t('mobilePhone')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="mobile"
                                            type="tel"
                                            placeholder={t('mobilePhone')}
                                            name="mobile"
                                            value={steps.formData.mobile}
                                            onChange={handleChange}
                                        />
                                        {formErrors.mobile && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.mobile}</p>
                                        )}
                                    </div>

                                    {/* Type de document d'dentification */}
                                    <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            {t('idDoc')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                        </label>
                                        <div className="flex">
                                            <label className="mr-4">
                                                <input
                                                    type="radio"
                                                    name="idDoc"
                                                    value="CNI"
                                                    checked={steps.formData.idDoc === "CNI"}
                                                    onChange={handleChange}
                                                />
                                                <span className="ml-2">{t('cni')}</span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="idDoc"
                                                    value="Passeport"
                                                    checked={steps.formData.idDoc === "Passeport"}
                                                    onChange={handleChange}
                                                />
                                                <span className="ml-2">{t('passeport')}</span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="idDoc"
                                                    value="Carte de séjour"
                                                    checked={steps.formData.idDoc === "Carte de séjour"}
                                                    onChange={handleChange}
                                                    className="ml-2"
                                                />
                                                <span className="ml-2">{t('sejourCard')}</span>
                                            </label>
                                        </div>
                                        {formErrors.idDoc && (
                                            <p className="text-red-500 text-sm mt-1">{formErrors.idDoc}</p>
                                        )}
                                    </div>

                                    {/* Champs de formulaire en fonction du type de document d'identification */}
                                    {steps.formData.idDoc === "CNI" && (
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`} style={{ zIndex: 999 }}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="idCardNumber">
                                                {t('cniNumber')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="idCardNumber"
                                                type="text"
                                                placeholder={t('cniNumber')}
                                                name="idCardNumber"
                                                value={steps.formData.idCardNumber}
                                                onChange={handleChange}
                                            />
                                            {formErrors.idCardNumber && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.idCardNumber}</p>
                                            )}

                                            <div className="py-2">
                                                <button
                                                    type="button"
                                                    onClick={() => setModalCniIsOpen(true)}
                                                    className="bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded-full"
                                                >
                                                    {t('selectCniImgs')}
                                                </button>

                                                <Modal
                                                    isOpen={modalCniIsOpen}
                                                    onRequestClose={() => setModalCniIsOpen(false)}
                                                    contentLabel="File Upload Modal"
                                                >
                                                    <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">{t('selectCniImgsTitle')}</h2>
                                                    <div className='font-montserrat mb-4'>
                                                        <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                                            {t('rectoCni')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            name="idCardFront"
                                                            onChange={e => {
                                                                setRectoPhotoCniImage(e.target.files[0]);
                                                            }}
                                                            className="block w-full text-sm text-slate-500
                                                            file:mr-4 file:py-2 file:px-4
                                                            file:rounded-full file:border-0
                                                            file:text-sm file:font-semibold
                                                            file:bg-sky-50 file:text-sky-700
                                                            hover:file:bg-sky-100 cursor-pointer"
                                                        />
                                                    </div>

                                                    <div className='font-montserrat mb-4'>
                                                        <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                                            {t('versoCni')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            name="idCardBack"
                                                            onChange={e => {
                                                                setVersoPhotoCniImage(e.target.files[0]);
                                                            }}
                                                            className="block w-full text-sm text-slate-500
                                                            file:mr-4 file:py-2 file:px-4
                                                            file:rounded-full file:border-0
                                                            file:text-sm file:font-semibold
                                                            file:bg-sky-50 file:text-sky-700
                                                            hover:file:bg-sky-100 cursor-pointer"
                                                        />
                                                    </div>

                                                    <div className="flex flex-col items-center space-y-4">
                                                        <div className="flex flex-col items-center">
                                                            {rectoPhotoCniImage ? (
                                                                <div className="border border-gray-300">
                                                                    <div>
                                                                        <AvatarEditor
                                                                            ref={editorRefRectoCni}
                                                                            image={rectoPhotoCniImage}
                                                                            width={270}
                                                                            height={150}
                                                                            border={10}
                                                                            color={[135, 206, 250, 0.6]}
                                                                            scale={scaleRectoCni}
                                                                            rotate={rotateRectoCni}
                                                                        />
                                                                    </div>

                                                                    <div className='flex flex-col items-center space-y-2'>
                                                                        <div className='flex items-center space-y-2'>
                                                                            <div className="my-2">
                                                                                <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    type="range"
                                                                                    id="scale"
                                                                                    min="0.1"
                                                                                    max="10"
                                                                                    step="0.1"
                                                                                    value={scaleRectoCni}
                                                                                    onChange={handleScaleChangeRectoCni}
                                                                                    className="w-48"
                                                                                />
                                                                                <span className="text-gray-500 font-bold mx-1">{scaleRectoCni}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center space-y-2">
                                                                            <div>
                                                                                <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    type="range"
                                                                                    id="rotate"
                                                                                    min="0"
                                                                                    max="360"
                                                                                    step="1"
                                                                                    value={rotateRectoCni}
                                                                                    onChange={handleRotateChangeRectoCni}
                                                                                    className="w-48"
                                                                                />
                                                                                <span className="text-gray-500 font-bold mx-1">{rotateRectoCni}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''}

                                                            {versoPhotoCniImage ? (
                                                                <div className="border border-gray-300">
                                                                    <div>
                                                                        <AvatarEditor
                                                                            ref={editorRefVersoCni}
                                                                            image={versoPhotoCniImage}
                                                                            width={270}
                                                                            height={150}
                                                                            border={10}
                                                                            color={[135, 206, 250, 0.6]}
                                                                            scale={scaleVersoCni}
                                                                            rotate={rotateVersoCni}
                                                                        />
                                                                    </div>

                                                                    <div className='flex flex-col items-center space-y-2'>
                                                                        <div className='flex items-center space-y-2'>
                                                                            <div className="my-2">
                                                                                <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    type="range"
                                                                                    id="scale"
                                                                                    min="0.1"
                                                                                    max="10"
                                                                                    step="0.1"
                                                                                    value={scaleVersoCni}
                                                                                    onChange={handleScaleChangeVersoCni}
                                                                                    className="w-48"
                                                                                />
                                                                                <span className="text-gray-500 font-bold mx-1">{scaleVersoCni}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex items-center space-y-2">
                                                                            <div>
                                                                                <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    type="range"
                                                                                    id="rotate"
                                                                                    min="0"
                                                                                    max="360"
                                                                                    step="1"
                                                                                    value={rotateVersoCni}
                                                                                    onChange={handleRotateChangeVersoCni}
                                                                                    className="w-48"
                                                                                />
                                                                                <span className="text-gray-500 font-bold mx-1">{rotateVersoCni}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ''}
                                                        </div>

                                                        {rectoPhotoCniImage && versoPhotoCniImage && (
                                                            <button
                                                                type="button"
                                                                onClick={previewHandleCni}
                                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full"
                                                            >
                                                                {t('valid')}
                                                            </button>
                                                        )}

                                                        {canvasRectoCni ? <canvas src={canvasRectoCni} /> : ''}
                                                        {canvasVersoCni ? <canvas src={canvasVersoCni} /> : ''}
                                                    </div>
                                                </Modal>
                                            </div>
                                            {formErrors.idCardFront && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.idCardFront}</p>
                                            )}
                                        </div>
                                    )}

                                    {steps.formData.idDoc === "Passeport" && (
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`} style={{ zIndex: 999 }}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="idPassportNumber">
                                                {t('passeportNumber')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="idPassportNumber"
                                                type="text"
                                                placeholder={t('passeportNumber')}
                                                name="idPassportNumber"
                                                value={steps.formData.idPassportNumber}
                                                onChange={handleChange}
                                            />
                                            {formErrors.idPassportNumber && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.idPassportNumber}</p>
                                            )}

                                            <label className="block text-gray-700 text-sm font-bold mb-2 mt-2">
                                                {t('passeportImg')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>

                                            <button
                                                type="button"
                                                onClick={() => setModalPassportIsOpen(true)}
                                                className="bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded-full"
                                            >
                                                {t('selectPasseportImg')}
                                            </button>

                                            <Modal
                                                isOpen={modalPassportIsOpen}
                                                onRequestClose={() => setModalPassportIsOpen(false)}
                                                contentLabel="File Upload Modal"
                                            >
                                                <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">{t('passeportTitle')}</h2>

                                                <div className='font-montserrat mb-4'>
                                                    <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                                        {t('passeportImg')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                    </label>
                                                    <input
                                                        type='file'
                                                        accept="image/jpeg, image/jpg, image/png"
                                                        name="idPasseport"
                                                        onChange={e => {
                                                            setPassportPhotoImage(e.target.files[0]);
                                                        }}
                                                        className="block py-2 px-4 w-full text-sm text-slate-500
                                                        file:mr-4 file:py-2 file:px-4
                                                        file:rounded-full file:border-0
                                                        file:text-sm file:font-semibold
                                                        file:bg-sky-50 file:text-sky-700
                                                        hover:file:bg-sky-100 cursor-pointer"
                                                    />
                                                </div>
                                                <div className="flex flex-col items-center space-y-4">
                                                    {passportPhotoImage ? <AvatarEditor
                                                        ref={editorRefPassport}
                                                        image={passportPhotoImage}
                                                        width={270}
                                                        height={150}
                                                        border={10}
                                                        color={[135, 206, 250, 0.6]}
                                                        scale={scalePassport}
                                                        rotate={rotatePassport}
                                                    /> : ''}

                                                    {passportPhotoImage && (
                                                        <div className='flex flex-col items-center space-y-2'>
                                                            <div className='flex items-center space-y-2'>
                                                                <div className="mx-2">
                                                                    <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="range"
                                                                        id="scale"
                                                                        min="0.1"
                                                                        max="10"
                                                                        step="0.1"
                                                                        value={scale}
                                                                        onChange={handleScaleChangePassport}
                                                                        className="w-48"
                                                                    />
                                                                    <span className="text-gray-500 font-bold mx-1">{scalePassport}</span>
                                                                </div>
                                                            </div>

                                                            <div className="flex items-center space-y-2">
                                                                <div className="mx-2">
                                                                    <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="range"
                                                                        id="rotate"
                                                                        min="0"
                                                                        max="360"
                                                                        step="1"
                                                                        value={rotatePassport}
                                                                        onChange={handleRotateChangePassport}
                                                                        className="w-48"
                                                                    />
                                                                    <span className="text-gray-500 font-bold mx-1">{rotatePassport}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {passportPhotoImage && (
                                                        <button
                                                            type="button"
                                                            onClick={previewHandlePassport}
                                                            className="font-montserrat bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full"
                                                        >
                                                            Valider
                                                        </button>
                                                    )}

                                                    {canvasPassport ? <canvas src={canvasPassport} /> : ''}
                                                </div>
                                            </Modal>

                                            {formErrors.idPasseportFront && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.idPasseportFront}</p>
                                            )}
                                        </div>
                                    )}

                                    {steps.formData.idDoc === "Carte de séjour" && (
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`} style={{ zIndex: 999 }}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="idResidencePermitNumber">
                                                {t('sejourCardNumber')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="idResidencePermitNumber"
                                                type="text"
                                                placeholder={t('sejourCardNumber')}
                                                name="idResidencePermitNumber"
                                                value={steps.formData.idResidencePermitNumber}
                                                onChange={handleChange}
                                            />
                                            {formErrors.idResidencePermitNumber && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.idResidencePermitNumber}</p>
                                            )}

                                            <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                                {t('sejourCardImg')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <button
                                                type="button"
                                                onClick={() => setModalResidenceIsOpen(true)}
                                                className="bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded-full"
                                            >
                                                {t('selectSejourCardImg')}
                                            </button>

                                            <Modal
                                                isOpen={modalResidenceIsOpen}
                                                onRequestClose={() => setModalResidenceIsOpen(false)}
                                                contentLabel="File Upload Modal"
                                            >
                                                <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">{t('selectSejourCardImgTitle')}</h2>
                                                <div className='font-montserrat mb-4'>
                                                    <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                                        {t('sejourCardImgRecto')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                    </label>
                                                    <input
                                                        type='file'
                                                        accept="image/jpeg, image/jpg, image/png"
                                                        name="idResidencePermit"
                                                        onChange={e => {
                                                            setResidencePhotoImage(e.target.files[0]);
                                                        }}
                                                        className="block py-2 px-4 w-full text-sm text-slate-500
                                                        file:mr-4 file:py-2 file:px-4
                                                        file:rounded-full file:border-0
                                                        file:text-sm file:font-semibold
                                                        file:bg-sky-50 file:text-sky-700
                                                        hover:file:bg-sky-100 cursor-pointer"
                                                    />
                                                </div>
                                                <div className="flex flex-col items-center space-y-4">
                                                    {residencePhotoImage ? <AvatarEditor
                                                        ref={editorRefResidence}
                                                        image={residencePhotoImage}
                                                        width={270}
                                                        height={150}
                                                        border={10}
                                                        color={[135, 206, 250, 0.6]}
                                                        scale={scaleResidence}
                                                        rotate={rotateResidence}
                                                    /> : ''}

                                                    {residencePhotoImage && (
                                                        <div className='flex flex-col items-center space-y-2'>
                                                            <div className='flex items-center space-y-2'>
                                                                <div className="mx-2">
                                                                    <label htmlFor="scale" className="font-montserrat text-gray-600 font-bold mx-1">Zoom:</label>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="range"
                                                                        id="scale"
                                                                        min="0.1"
                                                                        max="10"
                                                                        step="0.1"
                                                                        value={scale}
                                                                        onChange={handleScaleChangeResidence}
                                                                        className="w-48"
                                                                    />
                                                                    <span className="text-gray-500 font-bold mx-1">{scaleResidence}</span>
                                                                </div>
                                                            </div>

                                                            <div className="flex items-center space-y-2">
                                                                <div className="mx-2">
                                                                    <label htmlFor="rotate" className="font-montserrat text-gray-600 font-bold mx-1">Rotation:</label>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="range"
                                                                        id="rotate"
                                                                        min="0"
                                                                        max="360"
                                                                        step="1"
                                                                        value={rotateResidence}
                                                                        onChange={handleRotateChangeResidence}
                                                                        className="w-48"
                                                                    />
                                                                    <span className="text-gray-500 font-bold mx-1">{rotateResidence}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {residencePhotoImage && (
                                                        <button
                                                            type="button"
                                                            onClick={previewHandleResidence}
                                                            className="font-montserrat bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full"
                                                        >
                                                            {t('valid')}
                                                        </button>
                                                    )}

                                                    {canvasResidence ? <canvas src={canvasResidence} /> : ''}
                                                </div>

                                            </Modal>

                                            {formErrors.idResidencePermitFront && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.idResidencePermitFront}</p>
                                            )}
                                        </div>
                                    )}

                                    {steps.formData.customerType === "Entreprise" && (
                                        <>
                                            <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="regComNumber">
                                                    {t('comRegisterMsg')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="regComNumber"
                                                    type="text"
                                                    placeholder={t('comRegisterMsg')}
                                                    name="regComNumber"
                                                    value={steps.formData.regComNumber}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.regComNumber && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.regComNumber}</p>
                                                )}
                                            </div>
                                            {/* Recto du Registre de commerce (si entreprise */}
                                            {/* <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`} style={{ zIndex: 999 }}>
                                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                                    {t('regComDoc')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>

                                                <div className="flex items-center justify-center w-full mb-4">
                                                    <label
                                                        htmlFor="dropzone-file"
                                                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                                                    >
                                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                            <svg className="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5A5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                                />
                                                            </svg>
                                                            <p className="mb-2 text-sm text-gray-500">
                                                                <span className="font-semibold">{t('locationPlanClickToUpload')}</span> {t('locationPlanOrDragAndDrop')}
                                                            </p>
                                                        </div>
                                                        <input
                                                            id="dropzone-file"
                                                            type="file"
                                                            accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                            name="commerceRegistryFront"
                                                            onChange={handleFileChange}
                                                            className="hidden"
                                                        />
                                                    </label>
                                                </div>

                                                {formErrors.commerceRegistryFront && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.commerceRegistryFront}</p>
                                                )}
                                            </div> */}
                                            <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`} style={{ zIndex: 999 }}>
                                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                                    Image du Registre commerce <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>
                                                <input
                                                    type="file"
                                                    accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                    name="commerceRegistryFront"
                                                    onChange={handleFileChange}
                                                    className="block w-full text-sm text-slate-500
                                                    file:mr-4 file:py-2 file:px-4
                                                    file:rounded-full file:border-0
                                                    file:text-sm file:font-semibold
                                                    file:bg-sky-50 file:text-sky-700
                                                    hover:file:bg-sky-100 cursor-pointer"
                                                />
                                                {formErrors.commerceRegistryFront && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.commerceRegistryFront}</p>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {/* ETAPE 2 */}
                                    <section className={`py-2 ${steps.currentStep === 2 ? "" : "hidden"}`}>
                                        {!localStorage.getItem('selectedOffer') && (
                                            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                                                <div className='relative max-w-xl mx-auto sm:text-center'>
                                                    <h3 className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                                                        {t('offer')} <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                    </h3>
                                                </div>
                                                <div className='mt-16 space-y-6 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3'>
                                                    {plans && plans.length > 0 ? (
                                                        plans.map((item, idx) => (
                                                            <div key={idx} className='relative flex-1 flex items-stretch flex-col p-8 rounded-xl border-2 border-sky-500 text-center'>
                                                                <div>
                                                                    <span className='text-sky-600 font-medium text-3xl'>
                                                                        {item.nameOffer}
                                                                    </span>
                                                                    <div className='mt-4 text-gray-800 text-3xl font-semibold'>
                                                                        {item.price.toLocaleString()} FCFA <span className="text-xl text-gray-600 font-normal">/{t('month')}</span>
                                                                    </div>
                                                                </div>
                                                                <ul className='py-8 space-y-3'>
                                                                    <li key={idx} className='flex items-center gap-5'>
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            className='h-5 w-5 text-sky-600'
                                                                            viewBox='0 0 20 20'
                                                                            fill='currentColor'>
                                                                            <path
                                                                                fill-rule='evenodd'
                                                                                d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                                                                clip-rule='evenodd'></path>
                                                                        </svg>
                                                                        {t('installationFee')}
                                                                    </li>
                                                                    <li className='flex items-center gap-5'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-gauge text-sky-500">
                                                                            <path d="m12 14 4-4" />
                                                                            <path d="M3.34 19a10 10 0 1 1 17.32 0" />
                                                                        </svg>
                                                                        {t('maxSpeedUpTo')} {item.speed} Mbps
                                                                    </li>
                                                                    <li className='flex items-center gap-5'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-infinity text-sky-500">
                                                                            <path d="M12 12c-2-2.67-4-4-6-4a4 4 0 1 0 0 8c2 0 4-1.33 6-4Zm0 0c2 2.67 4 4 6 4a4 4 0 0 0 0-8c-2 0-4 1.33-6 4Z" />
                                                                        </svg>
                                                                        {t('unlimitedInternet')}
                                                                    </li>
                                                                    <li className='flex items-center gap-5'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone-call text-sky-500">
                                                                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /><path d="M14.05 2a9 9 0 0 1 8 7.94" />
                                                                            <path d="M14.05 6A5 5 0 0 1 18 10" />
                                                                        </svg>
                                                                        {item.MinsFixes} {t('callMinsFixes')}
                                                                    </li>
                                                                    <li className='flex items-center gap-5'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-smartphone-nfc text-sky-500">
                                                                            <rect width="7" height="12" x="2" y="6" rx="1" /><path d="M13 8.32a7.43 7.43 0 0 1 0 7.36" /><path d="M16.46 6.21a11.76 11.76 0 0 1 0 11.58" />
                                                                            <path d="M19.91 4.1a15.91 15.91 0 0 1 .01 15.8" />
                                                                        </svg>
                                                                        {item.MinsMobiles} {t('callMinsMobiles')}
                                                                    </li>
                                                                </ul>
                                                                <div className="flex-1 flex items-end">
                                                                    <button
                                                                        onClick={() => handleSelectPlan(item.nameOffer)}
                                                                        className={`px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white ${selectedPlan === item.nameOffer ? 'bg-sky-600' : 'bg-gray-400'
                                                                            } hover:bg-sky-500 active:bg-sky-700`}
                                                                        disabled={selectedPlan === item.nameOffer}
                                                                    >
                                                                        {selectedPlan === item.nameOffer ? 'Choisi' : 'Sélectionner'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : <p className="text-center">Chargement des offres en cours...</p>}
                                                </div>

                                                <p className="mt-7 text-center">
                                                    {t('byClikingNext1')} <a href="#" onClick={() => setModalConditionsIsOpen(true)} className="underline text-sky-600">{t('byClikingNextMiddle')}</a>{t('byClikingNext2')}.

                                                    <Modal
                                                        isOpen={modalConditionsIsOpen}
                                                        onRequestClose={() => setModalConditionsIsOpen(false)}

                                                    >
                                                        <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">{t('contractTitle')}</h2>
                                                        <div className="font-montserrat flex flex-col space-y-4">
                                                            <p className="text-justify text-xs">{t('contractText1')}</p>
                                                            <p className="text-justify text-xs">{t('contractText2')}</p>
                                                            <p className="text-justify text-xs">{t('contractText3')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article1Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article1Text1')}</p>
                                                            <p className="text-justify text-xs">{t('article1Text2')}</p>
                                                            <p className="text-justify text-xs">{t('article1Text3')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article2Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article2Text1')}</p>
                                                            <p className="text-justify text-xs">{t('article2Text2')}</p>
                                                            <p className="text-justify text-xs">{t('article2Text3')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article3Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article3Text1')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article4Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article4Text1')}</p>
                                                            <table className="table-auto w-full">
                                                                <tr>
                                                                    <th>{t('article4TableTh1')}</th><th>{t('article4TableTh2')}</th><th>{t('article4TableTh3')}</th><th>{t('article4TableTh4')}</th><th>{t('article4TableTh5')}</th><th>{t('article4TableTh6')}</th>
                                                                </tr>
                                                                <tr><td className="border text-center">S</td><td className="border text-center">15 Mbps</td><td className="border text-center">{t('unlimited')}</td><td className="border text-center">200 mins</td><td className="border text-center">90 mins</td><td className="border text-center">25 000</td></tr>
                                                                <tr><td className="border text-center">M</td><td className="border text-center">25 Mbps</td><td className="border text-center">{t('unlimited')}</td><td className="border text-center">300 mins</td><td className="border text-center">120 mins</td><td className="border text-center">35 000</td></tr>
                                                                <tr><td className="border text-center">L</td><td className="border text-center">40 Mbps</td><td className="border text-center">{t('unlimited')}</td><td className="border text-center">400 mins</td><td className="border text-center">150 mins</td><td className="border text-center">50 000</td></tr>
                                                                <tr><td className="border text-center">XL</td><td className="border text-center">50 Mbps</td><td className="border text-center">{t('unlimited')}</td><td className="border text-center">500 mins</td><td className="border text-center">180 mins</td><td className="border text-center">60 000</td></tr>
                                                            </table>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article5Title')}</h1>
                                                            <p className="text-justify text-xs">
                                                                {t('article5Text1')}
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article6Title')}</h1>
                                                            <p className="text-justify text-xs">
                                                                {t('article6Text1')}
                                                                <ul className="list-disc ml-7">
                                                                    <li>{t('article6Text1List1')}</li>
                                                                    <li>{t('article6Text1List2')}</li>
                                                                    <li>{t('article6Text1List3')}</li>
                                                                    <li>{t('article6Text1List4')}</li>
                                                                    <li>{t('article6Text1List5')}</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                {t('article6Text2')}
                                                                <ul className="list-disc ml-7">
                                                                    <li>{t('article6Text2List1')}</li>
                                                                    <li>{t('article6Text2List2')}</li>
                                                                    <li>{t('article6Text2List3')}
                                                                        <ul>
                                                                            <li>{t('article6Text2List3Sub1')}</li>
                                                                            <li>{t('article6Text2List3Sub2')}</li>
                                                                            <li>{t('article6Text2List3Sub3')}</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article7Title')}</h1>
                                                            <p className="text-justify text-xs">
                                                                {t('article6Text1')}
                                                                <ul className="list-disc ml-7">
                                                                    <li>{t('article7Text1List1')}</li>
                                                                    <li>{t('article7Text1List2')}</li>
                                                                    <li>{t('article7Text1List3')}</li>
                                                                    <li>{t('article7Text1List4')}</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">{t('article6Text2List1')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article8Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article8Text1')}</p>
                                                            <p className="text-justify text-xs">{t('article8Text2')}</p>
                                                            <p className="text-justify text-xs">{t('article8Text3')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article9Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article9Text1')}</p>
                                                            <p className="text-justify text-xs">{t('article9Text2')}</p>
                                                            <p className="text-justify text-xs">{t('article9Text3')}</p>
                                                            <p className="text-justify text-xs">
                                                                <ul className="list-disc ml-7">
                                                                    <li>{t('article9Text3List1')}</li>
                                                                    <li>{t('article9Text3List2')}</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">{t('article9Text4')}</p>
                                                            <p className="text-justify text-xs">{t('article9Text5')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article10Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article10Text1')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article11Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article11Text1')}</p>
                                                            <p className="text-justify text-xs">
                                                                {t('article11Text2')}
                                                                <ul className="list-disc ml-7">
                                                                    <li>{t('article11Text2List1')}</li>
                                                                    <li>{t('article11Text2List2')}</li>
                                                                    <li>{t('article11Text2List3')}</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article12Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article12Text1')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article13Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article13Text1')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article14Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article14Text1')}</p>
                                                            <p className="text-justify text-xs">{t('article14Text2')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article15Title')}</h1>
                                                            <p className="text-justify text-xs font-bold">{t('article15Text1')}</p>
                                                            <p className="text-justify text-xs">{t('article15Text11')}</p>
                                                            <p className="text-justify text-xs">{t('article15Text12')}</p>
                                                            <p className="text-justify text-xs">{t('article15Text13')}</p>
                                                            <p className="text-justify text-xs font-bold">{t('article15Text2')}</p>
                                                            <p className="text-justify text-xs">
                                                                {t('article15Text2Text')}
                                                                <ul className="list-disc ml-7">
                                                                    <li>{t('article15Text2List1')}</li>
                                                                    <li>{t('article15Text2List2')}</li>
                                                                    <li>{t('article15Text2List3')}</li>
                                                                    <li>{t('article15Text2List4')}</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs font-bold">{t('article15Text3')}</p>
                                                            <p className="text-justify text-xs">{t('article15Text3Text')}</p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">{t('article16Title')}</h1>
                                                            <p className="text-justify text-xs">{t('article16Text')}</p>
                                                        </div>

                                                        <button type="button" onClick={acceptConditions} className="font-montserrat bg-sky-500 hover:bg-sky-600 text-white px-7 py-2 float-right bold rounded-full">
                                                            {t('nextBtn')}
                                                        </button>
                                                    </Modal>
                                                </p>
                                            </div>
                                        )}

                                        <div className="mt-3">
                                            <h2 className="mb-3 text-center font-bold text-2xl">{t('customerSignatureInTheBox')}</h2>
                                            <div className="border-2 border-gray-700">
                                                <SignatureCanvas
                                                    penColor='black'
                                                    canvasProps={{ width: 770, height: 200, className: 'sigCanvas' }}
                                                    ref={sigCanvas}
                                                    onEnd={saveSignature}
                                                />
                                            </div>
                                            <div className="p-2 text-center cursor-pointer font-bold text-red-500" onClick={clearSignature}>{t('clearCustomerSignature')}</div>
                                        </div>

                                        <div className="flex items-center justify-center mt-5 py-2">
                                            <div>
                                                <ReCAPTCHA
                                                    sitekey={config.REACT_APP_RECAPTCHA_SITEKEY}
                                                    onChange={(val) => setCapVal(val)}
                                                />
                                            </div>
                                        </div>
                                    </section>

                                    {/* ETAPE 3 */}
                                    <section className={`py-14 ${steps.currentStep === 3 ? "" : "hidden"}`}>
                                        <div className="mb-4 mt-4 flex items-center justify-between">
                                            <div className="w-28 h-23 mr-4 ">
                                                <img src={logoCamtel} alt="Logo CAMTEL" className="w-full h-full" />
                                            </div>

                                            <div className="w-full flex flex-col items-center">
                                                <div className="w-100 h-10 mb-2">
                                                    <img src={logosFtth} alt="icônes FTTH" className="w-full h-full" style={{ width: 250, height: 60 }} />
                                                </div>
                                                <h2 className="text-sky-400 text-1xl mt-4 font-bold sm:text-4xl text-center whitespace-nowrap">
                                                    {t('subscribeForm')}
                                                </h2>
                                            </div>

                                            <div className="flex items-center mb-2">
                                                <div className="w-24 h-20 ml-4">
                                                    {photoPreview && (
                                                        <div>
                                                            <img
                                                                src={photoPreview}
                                                                alt={t('customerPhoto')}
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: '100%', width: '100%' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <p><strong>{t('nameOrSocialName')}: </strong> {steps.formData.nameOrSocialName}</p>
                                        <p><strong>{t('customerType')}: </strong> {steps.formData.customerType}</p>
                                        <p><strong>{t('region')}: </strong>
                                            {
                                                selectedOption || formData.region === 'CE' ? t('ce') :
                                                    selectedOption || formData.region === 'LT' ? t('lt') :
                                                        selectedOption || formData.region === 'NO' ? t('no') :
                                                            selectedOption || formData.region === 'EN' ? t('en') :
                                                                selectedOption || formData.region === 'AD' ? t('ad') :
                                                                    selectedOption || formData.region === 'ES' ? t('es') :
                                                                        selectedOption || formData.region === 'SU' ? t('su') :
                                                                            selectedOption || formData.region === 'OU' ? t('ou') :
                                                                                selectedOption || formData.region === 'NW' ? t('nw') :
                                                                                    selectedOption || formData.region === 'SW' ? t('sw') : null
                                            }
                                        </p>
                                        <p><strong>{t('city')}: </strong> {formData.city}</p>
                                        <p><strong>{t('neighborhood')}: </strong> {formData.preciseLocation}</p>
                                        <p><strong>{t('lieuDit')}: </strong> {steps.formData.lieuDit}</p>
                                        <p><strong>{t('customerGpsCoords')}: </strong> {formData.customerGpsCoordinates}</p>
                                        <p>
                                            <strong>{t('locationPlanImg')}: </strong>
                                            <div className="h-full">
                                                {photoLocationPreview && (
                                                    <div>
                                                        {pdfLocationPreview ? (
                                                            <iframe
                                                                src={pdfLocationFile}
                                                                className="h-screen w-full"
                                                                title="PDF Viewer"
                                                            ></iframe>
                                                        ) : (
                                                            <img
                                                                src={photoLocationPreview}
                                                                alt="Image du plan de localisation"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 'auto', width: 'auto' }}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </p>
                                        <p><strong>{t('emailAdress')}: </strong> {steps.formData.email}</p>
                                        <p><strong>{t('mobilePhone')}: </strong> {steps.formData.mobile}</p>
                                        <p><strong>{t('idDoc')}: </strong> {steps.formData.idDoc}</p>
                                        {steps.formData.idCardNumber ? (
                                            <>
                                                <p><strong>{t('cniNumber')} </strong> {steps.formData.idCardNumber}</p>
                                                <div>
                                                    {photoRectoCniPreview && photoVersoCniPreview && (
                                                        <div className="flex">
                                                            <img
                                                                src={photoRectoCniPreview}
                                                                alt="Recto de la CNI"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: '100%', width: '50%' }}
                                                            />
                                                            <img
                                                                src={photoVersoCniPreview}
                                                                alt="Verso de la CNI"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: '100%', width: '50%' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        ) : steps.formData.idPassportNumber ? (
                                            <>
                                                <p><strong>{t('passeportNumber')}: </strong> {steps.formData.idPassportNumber}</p>
                                                <div>
                                                    {photoPassportPreview && (
                                                        <div className="flex">
                                                            <img
                                                                src={photoPassportPreview}
                                                                alt="Passeport"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: '100%', width: '100%' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        ) : steps.formData.idResidencePermitNumber ? (
                                            <>
                                                <p><strong>{t('sejourCardNumber')}: </strong> {steps.formData.idResidencePermitNumber}</p>
                                                <div>
                                                    {photoResidencePreview && (
                                                        <div className="flex">
                                                            <img
                                                                src={photoResidencePreview}
                                                                alt="Carte de séjour"
                                                                style={{ height: '100%', width: '100%' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        ) : null}

                                        {steps.formData.regComNumber && (
                                            <div>
                                                <p><strong>{t('comRegisterNumber')}: </strong> {steps.formData.regComNumber}</p>
                                                <div className="h-full">
                                                    {photoRegComPreview && (
                                                        <div>
                                                            {pdfRegComPreview ? (
                                                                <iframe
                                                                    src={pdfRegComFile}
                                                                    className="h-screen w-full"
                                                                    title="PDF Viewer"
                                                                ></iframe>
                                                            ) : (
                                                                <img
                                                                    src={photoRegComPreview}
                                                                    alt="Photo du registre de commerce"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: 'auto', width: 'auto' }}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <p><strong>{t('offerSelected')}: </strong> {selectedPlan}</p>
                                        <div>
                                            <p><strong>Signature: </strong></p>
                                            <p>
                                                <img src={formData.signature} />
                                            </p>
                                        </div>
                                    </section>

                                    <div className="mt-4 flex justify-end">
                                        {steps.currentStep > 1 && (
                                            <button type="button" onClick={prevStep} className="bg-sky-500 hover:bg-sky-600 text-white px-4 py-2 rounded-full">
                                                {t('previous')}
                                            </button>
                                        )}

                                        {steps.currentStep < steps.stepsItems.length && (
                                            <button
                                                type="button" onClick={nextStep}
                                                disabled={steps.currentStep === 2 && (!selectedPlan || !capVal || !signature)}
                                                className={`px-4 py-2 ml-2 rounded-full ${steps.currentStep === 2 && (!selectedPlan || !capVal || !signature) ? 'bg-gray-300 cursor-not-allowed' : 'bg-sky-500 hover:bg-sky-600 text-white'}`}
                                            >
                                                {t('nextBtn')}
                                            </button>
                                        )}

                                        {steps.currentStep === 3 && (
                                            <button
                                                type="submit"
                                                className={`bg-sky-500 hover:bg-sky-600 text-white px-4 py-2 ml-2 rounded-full ${selectedPlan ? "" : "opacity-50 cursor-not-allowed"}`}
                                                disabled={!selectedPlan || isButtonDisabled}
                                            >
                                                {t('submit')}
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>

                            <WhatsAppButton />

                            <Footer />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Sheet;
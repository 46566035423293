import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from '../locales/en/translation.json';
import translationsInFr from '../locales/fr/translation.json';

const resources = {
    fr: {
        translation: translationsInFr
    },
    en: {
        translation: translationsInEng
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "fr",
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        ns: "translation",
        defaultNS: "translation"
    });

export default i18n;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/icons/fr.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../img/icons/uk.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-selector {
    position: relative;
}

.language-select {
    appearance: none;
    padding: 5px 10px;
    font-size: 16px;
    background-color: white;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 20px;
    padding-left: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.language-select option {
    background-size: 20px;
    padding-left: 30px;
}

.language-select option[value="fr"]::before {
    content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    margin-right: 5px;
}

.language-select option[value="en"]::before {
    content: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    margin-right: 5px;
}

.language-select:focus {
    outline: none;
    border-color: #33b5e5;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/LanguageSelector.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,uBAAuB;IACvB,yDAA4C;IAC5C,4BAA4B;IAC5B,gCAAgC;IAChC,qBAAqB;IACrB,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,gDAAmC;IACnC,iBAAiB;AACrB;;AAEA;IACI,gDAAmC;IACnC,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".language-selector {\n    position: relative;\n}\n\n.language-select {\n    appearance: none;\n    padding: 5px 10px;\n    font-size: 16px;\n    background-color: white;\n    background-image: url('../img/icons/fr.png');\n    background-repeat: no-repeat;\n    background-position: 10px center;\n    background-size: 20px;\n    padding-left: 40px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.language-select option {\n    background-size: 20px;\n    padding-left: 30px;\n}\n\n.language-select option[value=\"fr\"]::before {\n    content: url('../img/icons/fr.png');\n    margin-right: 5px;\n}\n\n.language-select option[value=\"en\"]::before {\n    content: url('../img/icons/uk.png');\n    margin-right: 5px;\n}\n\n.language-select:focus {\n    outline: none;\n    border-color: #33b5e5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

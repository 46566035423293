// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    bottom: 0;
    left: 0;
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Footer.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,OAAO;IACP,QAAQ;AACZ","sourcesContent":[".footer {\n    bottom: 0;\n    left: 0;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

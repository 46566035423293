import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import Profile from './pages/admin/Profile';
import Sheet from './pages/Sheet';
import AdminHome from './pages/admin/AdminHome';
import Partner from './pages/admin/Partner';
import Order from './pages/admin/Order';
import SuperOrder from './pages/superadmin/SuperOrder';
import SuperSheet from './pages/superadmin/SuperSheet';
import PartnerSheet from './pages/partner/PartnerSheet';
import PreStudyOrder from './pages/admin/PreStudyOrder';
import Activation from './pages/admin/Activation';
import Fiche from './pages/Fiche';
import Agency from './pages/superadmin/Agency';
import Installation from './pages/admin/Installation';
import Index from './pages/Index';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import PaySubscription from './pages/PaySubscription';
import Receipt from './pages/Receipt';
import Payment from './pages/superadmin/Payment';
import Location from './pages/Location';
import Verify from './pages/Verify';
import PdfSubscriptioForm from './pages/PdfSubscriptioForm';
import Feedback from './pages/Feedback';
import FollowUp from './pages/FollowUp';
import Rdv from './pages/Rdv';
import AdminLocation from './pages/admin/AdminLocation';
import Appointment from './pages/superadmin/Appointment';
import Settings from './pages/admin/Settings';
import Help from './pages/admin/Help';
import FeedbackList from './pages/superadmin/FeedbackList';
import VerifPayment from './pages/superadmin/VerifPayment';
import Contract from './pages/admin/Contract';
import ComingSoon from './pages/ComingSoon';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Index />} />
        {/* <Route path="/" index element={<Home/>} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/resetpassword/:hash" element={<ResetPassword />} />
        <Route path="/agency" element={<Agency />} />
        <Route path="/sheet" element={<Sheet />} />
        <Route path="/pay-subscription/:id" element={<PaySubscription />} />
        <Route path="/super-sheet" element={<SuperSheet />} />
        <Route path="/dashboard" element={<AdminHome />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/order" element={<Order />} />
        <Route path="/super-order" element={<SuperOrder />} />
        <Route path="/partner-sheet" element={<PartnerSheet />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/pre-study-order" element={<PreStudyOrder />} />
        <Route path="/activation" element={<Activation />} />
        <Route path="/installation" element={<Installation />} />
        <Route path="/fiche/:id" element={<Fiche />} />
        <Route path="/receipt/:cbsOrderId" element={<Receipt />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/verify/:id" element={<Verify />} />
        <Route path="/location/:id" element={<Location />} />
        <Route path="/rdv/:id" element={<Rdv />} />
        <Route path="/order/pdf/:idSheet" element={<PdfSubscriptioForm />} />
        <Route path="/feedback/:idSheet" element={<Feedback />} />
        <Route path="/follow-up" element={<FollowUp />} />
        <Route path="/admin-location/:id" element={<AdminLocation />} />
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/help" element={<Help />} />
        <Route path="/feedback-list" element={<FeedbackList />} />
        <Route path="/verif-payment" element={<VerifPayment />} />
	<Route path="/contract/:id" element={<Contract />} />
	<Route path="/coming-soon" element={<ComingSoon />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

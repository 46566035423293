import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const Navbar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [showGpsModal, setShowGpsModal] = useState(false);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);

    const [state, setState] = useState(false);

    const handleRequestClick = () => {
        localStorage.removeItem("selectedOffer");
        setShowModal(true);
    };

    const handleFirstQuestionResponse = (response) => {
        if (response === 'yes') {
            navigate('/sheet');
        } else {
            setShowModal(false);
            setShowGpsModal(true);
        }
    };

    const handleGpsQuestionResponse = (response) => {
        if (response === 'yes') {
            navigate('/sheet');
        } else {
            setShowGpsModal(false);
            setShowThankYouMessage(true);
        }
    };

    const phoneNumber = '+237222202020';

    const navigation = [
        { title: `${t('descoveroffers')}`, path: "#plan" },
        {
            title: `${t('doAdemand')}`,
            onClick: handleRequestClick,
        },
        { title: `${t('followUp')}`, path: "/follow-up" },
        {
            title: `${t('reportFraud')}`,
            path: `https://wa.me/${phoneNumber}`,
            newTab: true,
        },
        // { title: `${t('breakdown')}`, path: "/break-down" }
    ];

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'fr';
        i18n.changeLanguage(storedLanguage);
    }, []);

    return (
        <nav className="bg-white border-b w-full md:static md:text-sm md:border-none shadow-lg">
            <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8">
                <div className="flex items-center justify-between py-2 md:py-2 md:block">
                    <a href="javascript:void(0)">
                        <img
                            src={require('../assets/img/logo/fiberconnect-logo-no-bg.png')}
                            width={120}
                            height={50}
                            alt="FIBERCONNECT Logo"
                        />
                    </a>
                    <div className="md:hidden">
                        <button className="text-gray-500 hover:text-gray-800"
                            onClick={() => setState(!state)}
                        >
                            {
                                state ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                )
                            }
                        </button>
                    </div>
                </div>
                <div className={`flex-1 pb-3 mt-8 md:block md:pb-0 md:mt-0 ${state ? 'block' : 'hidden'}`}>
                    <ul className="justify-end items-center space-y-6 md:flex md:space-x-3 md:space-y-0">
                        {navigation.map((item, idx) => (
                            <li key={idx} className="text-gray-700 hover:text-sky-600 hover:font-semibold">
                                {item.onClick ? (
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            item.onClick();
                                        }}
                                        className="block"
                                    >
                                        {item.title}
                                    </button>
                                ) : (
                                    <a
                                        href={item.path}
                                        target={item.newTab ? "_blank" : "_self"}
                                        rel={item.newTab ? "noopener noreferrer" : ""}
                                        className="block"
                                    >
                                        {item.title}
                                    </a>
                                )}
                            </li>
                        ))}
                        <span className='hidden w-px h-6 bg-gray-300 md:block'></span>
                        {/* <div className='space-y-3 items-center gap-x-6 md:flex md:space-y-0'>
                            <li>
                                <a href="/coming-soon" target='_blank' className="block py-3 px-4 font-medium text-center text-white bg-sky-500 hover:bg-sky-600 active:bg-sky-700 active:shadow-none rounded-lg shadow md:inline">
                                    {t('myAccount')}
                                </a>
                            </li>
                        </div> */}
                        <LanguageSelector />
                    </ul>
                </div>
            </div>

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center">
                            {t('q1')}
                        </h2>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                onClick={() => handleFirstQuestionResponse('yes')}
                            >
                                {t('yesResponse')}
                            </button>
                            <button
                                className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                onClick={() => handleFirstQuestionResponse('no')}
                            >
                                {t('noResponse')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showGpsModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center">
                            {t('q2')}
                        </h2>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                onClick={() => handleGpsQuestionResponse('yes')}
                            >
                                {t('yesResponse')}
                            </button>
                            <button
                                className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                onClick={() => handleGpsQuestionResponse('no')}
                            >
                                {t('noResponse')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showThankYouMessage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center text-gray-700">
                            {t('msg1')}
                        </h2>
                        <p className="text-center text-gray-600">
                            {t('msg2')}
                        </p>
                        <div className="mt-4 flex justify-center">
                            <button
                                className="px-6 py-2 bg-sky-500 text-white rounded-lg hover:bg-sky-600"
                                onClick={() => setShowThankYouMessage(false)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Navbar;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { BASE_URL } from "../../apiConfig";
import '../../style.css';

const Partner = () => {
    const [agency, setAgency] = useState([]);

    const [partners, setPartners] = useState([]);
    const [formData, setFormData] = useState({ name: "", email: "", password: "camtel", role: "admin_partner", telephone: "", idBindedEntity: localStorage.getItem('userRole') === 'super_admin' ? agency : localStorage.getItem('userId') });
    const [isAdding, setIsAdding] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [editingPartner, setEditingPartner] = useState(null);

    const [editedName, setEditedName] = useState("");
    const [editedEmail, setEditedEmail] = useState("");
    const [editedTelephone, setEditedTelephone] = useState("");
    const [editedInterventionZone, setEditedInterventionZone] = useState("");

    const [loading, setLoading] = useState(false);
    const [agencies, setAgencies] = useState([]);

    const [details, setDetails] = useState([]);

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [isFormEditVisible, setIsFormEditVisible] = useState(true);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    // Get Only CAMTEL agencies name
    useEffect(() => {
        if (token) {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/agency/visible/`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setAgencies(response.data.entities);
                } catch (error) {
                    console.error("Erreur lors de la récupération des agences :", error);
                }
            };

            fetchData();
        }
    }, [token]);

    // By Admin CAMTEL
    useEffect(() => {
        if (localStorage.getItem('userRole') === 'admin_camtel' && token) {
            const fetchData = async () => {
                const userId = localStorage.getItem('userId');

                try {
                    const response = await axios.get(`${BASE_URL}/api/entities/${userId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setPartners(response.data.entities);
                } catch (error) {
                    console.error("Erreur lors de la récupération des partenaires :", error);
                }
            };

            fetchData();
        }
    }, [formData, editingPartner, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDetails = async (id) => {
        const partner = partners.find((partner) => partner.idEntity === id);
        setSelectedPartner(partner);
        setEditingPartner(false);
        setIsAdding(false);

        if (token) {
            try {
                const response = await axios.get(`${BASE_URL}/api/entities/details/` + partner.idBindedEntity, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                setDetails(response.data.entities);
                setIsDetailsVisible(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        }
    };

    const handleAdd = () => {
        setLoading(true);

        if (!formData.name || !formData.email || !formData.telephone || !agency) {
            setLoading(false);
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const data = JSON.stringify(formData);

        if (token) {
            const config = {
                method: 'post',
                url: `${BASE_URL}/api/entities`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    if (localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'super_admin') {
                        setFormData({
                            name: "",
                            email: "",
                            password: "camtel123",
                            role: "admin_partner",
                            telephone: "",
                            idBindedEntity: localStorage.getItem('userId')
                        });
                    } else {
                        setFormData({
                            name: "",
                            email: "",
                            password: "camtel123",
                            role: "admin_camtel",
                            telephone: "",
                            idBindedEntity: agency
                        });
                    }

                    setPartners([...partners, response.data]);
                    setEditingPartner(false);
                    setIsAdding(false);
                    setLoading(false);

                    setIsFormVisible(true);
                })
                .catch((error) => {
                    console.error("Erreur lors de la création du partenaire : ", error);
                    setLoading(false);
                    alert('Erreur lors de la création du partenaire !');
                });
        }
    };

    const handleEdit = (id) => {
        setSelectedPartner(false);
        setIsAdding(false);

        const partner = partners.find((partner) => partner.idEntity === id);
        setEditingPartner(partner);
        setEditedName(partner.name);
        setEditedEmail(partner.emailEntity);
        setEditedTelephone(partner.telephoneEntity);
        setEditedInterventionZone(partner.interventionZone);

        setIsFormEditVisible(false);
    };

    const handleSubmit = () => {
        setLoading(true);

        if (!editedName || !editedEmail || !editedTelephone) {
            setLoading(false);
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const updatedData = {
            ...editingPartner,
            name: editedName,
            email: editedEmail,
            telephone: editedTelephone,
            interventionZone: editedInterventionZone,
        };

        if (token) {
            axios.put(`${BASE_URL}/api/entities/${editingPartner.idEntity}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    alert('Partenaire modifiée avec succès.');
                    setEditingPartner(null);
                    setIsFormEditVisible(true);
                    setLoading(false);
                    setIsDetailsVisible(true);
                })
                .catch((error) => {
                    console.error("Erreur lors de la mise à jour du partenaire : ", error);
                    setLoading(false);
                });
        }
    };

    const handleDelete = (id) => {
        setLoading(true);

        if (token && window.confirm('Êtes-vous sûr de vouloir désactiver ce partenaire ? Vous ne pourrez désormais plus lui assigner des commandes après cette action.')) {

            axios.patch(`${BASE_URL}/api/entities/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    setLoading(false);
                    setEditingPartner(false);
                    setIsAdding(false);
                    setIsDetailsVisible(true);
                })
                .catch((error) => {
                    console.error("Erreur lors de la suppression de l'entité : ", error);
                    setLoading(false);
                });
        }

        setLoading(false);
    };

    const reactivatePartner = (id) => {
        setLoading(true);

        if (token && window.confirm('Êtes-vous sûr de vouloir réactiver ce partenaire ? Vous pourrez désormais être en mesure de lui assigner des commandes et ce partenaire ne pourra plus accéder à la plateforme après cette action.')) {
            axios.patch(`${BASE_URL}/api/entities/reactivate/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Erreur lors de la suppression de l'entité : ", error);
                    setLoading(false);
                });
        }

        setLoading(false);
    }

    // By Super Admin CAMTEL
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'super_admin') {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/entities/superadmin/`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setPartners(response.data.entities);
                } catch (error) {
                    console.error("Erreur lors de la récupération des partenaires :", error);
                }
            };

            fetchData();
        }
    }, [formData, editingPartner, handleDelete, reactivatePartner, token]);

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-points.webp')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <Header />

                    <Sidebar setHideText={setHideText} hideText={hideText} />

                    <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                        <div className="w-full">
                            {isDetailsVisible && isFormVisible && isFormEditVisible && (
                                <div>
                                    <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des Partenaires</h1>

                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => { setIsAdding(true); setSelectedPartner(false); setEditingPartner(false); setIsFormVisible(false); }}
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-4"
                                        >
                                            AJOUTER
                                        </button>
                                    </div>

                                    <div className="mt-3 overflow-x-auto">
                                        <table className="w-full table-auto">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2">Nom du Partenaire</th>
                                                    <th className="text-center px-4 py-2">Email</th>
                                                    <th className="text-center px-4 py-2">Numero de téléphone</th>
                                                    <th className="text-center px-4 py-2">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-gray-600 divide-y">
                                                {Array.isArray(partners) && partners.length > 0 ? (
                                                    partners.map((partner) => (
                                                        <tr key={partner.idEntity} className={partner.visible === 0 ? "text-red-500 border-b hover:bg-gray-100" : "border-b hover:bg-gray-100"}>
                                                            <td className="px-4 py-2">{partner.name}</td>
                                                            <td className="px-4 py-2 text-center">{partner.emailEntity}</td>
                                                            <td className="px-4 py-2 text-center">{partner.telephoneEntity}</td>
                                                            <td className="px-4 py-2 text-center">
                                                                {partner.visible === 1 ? (
                                                                    <button
                                                                        onClick={() => handleDetails(partner.idEntity)}
                                                                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 m-1"
                                                                    >
                                                                        Détails
                                                                    </button>)
                                                                    : (
                                                                        <button
                                                                            onClick={() => reactivatePartner(partner.idEntity)}
                                                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 m-1"
                                                                        >
                                                                            Réactiver
                                                                        </button>
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">Aucun partenaire trouvé.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                            {selectedPartner && !isDetailsVisible && (
                                <div className="mt-4">
                                    <div className='flex justify-end'>
                                        <div>
                                            <button
                                                onClick={() => handleEdit(selectedPartner.idEntity)}
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 m-1"
                                            >
                                                Modifier
                                            </button>
                                            <button
                                                onClick={() => handleDelete(selectedPartner.idEntity)}
                                                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold p-2 m-1"
                                            >
                                                Désactiver
                                            </button>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 m-1 text-white"
                                                onClick={() => setIsDetailsVisible(true)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-4xl font-bold mb-4 mt-4 text-center">Détails du Partenaire</h2>
                                    <div>
                                        <strong>Nom du Partenaire:</strong> {selectedPartner.name}
                                    </div>
                                    <div>
                                        <strong>Email:</strong> {selectedPartner.emailEntity}
                                    </div>
                                    <div>
                                        <strong>Téléphone:</strong> {selectedPartner.telephoneEntity}
                                    </div>
                                    {details[0] && (
                                        <>
                                            <div>
                                                <strong>Agence d'attache:</strong> {details[0].name}
                                            </div>
                                            <div>
                                                <strong>Zone d'intervention:</strong> {details[0].interventionZone}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}

                            {isAdding && !isFormVisible && (
                                <div className="mt-4">
                                    <div className='flex justify-end'>
                                        <div>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => setIsFormVisible(true)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-4xl font-bold mb-4 mt-4 text-center">Ajouter un Partenaire</h2>
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="name" className="block text-gray-700 font-bold">
                                                Nom du Partenaire <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="email" className="block text-gray-700 font-bold">
                                                E-mail <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="telephone" className="block text-gray-700 font-bold">
                                                Téléphone <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="telephone"
                                                name="telephone"
                                                value={formData.telephone}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>

                                        {localStorage.getItem('userRole') === 'super_admin' && (
                                            <>
                                                <label htmlFor="agency" className="block text-gray-700 font-bold">
                                                    Agence d'attache <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>
                                                <select
                                                    id="agency"
                                                    name="agency"
                                                    className="border rounded w-full py-2 px-3"
                                                    value={agency}
                                                    onChange={(event) => {
                                                        setFormData({ ...formData, idBindedEntity: event.target.value });
                                                        setAgency(event.target.value);
                                                    }}
                                                >
                                                    <option value="">Sélectionnez une agence</option>
                                                    {Array.isArray(agencies) && agencies.length > 0 ? (
                                                        agencies.map((ag) => (
                                                            <option key={ag.idEntity} value={ag.idEntity}>{ag.name}</option>
                                                        ))
                                                    ) : (
                                                        <option className="text-center">Aucune agence trouvée.</option>
                                                    )}
                                                </select>
                                            </>
                                        )}

                                        <button
                                            type="button"
                                            onClick={handleAdd}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-4"
                                        >
                                            Ajouter
                                        </button>
                                    </form>
                                </div>
                            )}

                            {/* Formulaire de modification */}
                            {editingPartner && !isFormEditVisible && (
                                <div className="mt-4">
                                    <div className='flex justify-end'>
                                        <div>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => { setIsFormEditVisible(true); setIsDetailsVisible(true); }}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-4xl font-bold mb-4 mt-4 text-center">Modifier le Partenaire</h2>
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="name" className="block text-gray-700 font-bold">
                                                Nom du Partenaire <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={editedName}
                                                onChange={(e) => setEditedName(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="email" className="block text-gray-700 font-bold">
                                                E-mail <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={editedEmail}
                                                onChange={(e) => setEditedEmail(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="telephone" className="block text-gray-700 font-bold">
                                                Téléphone <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="telephone"
                                                id="telephone"
                                                name="telephone"
                                                value={editedTelephone}
                                                onChange={(e) => setEditedTelephone(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        {/* <div className="mb-2">
                                        <label htmlFor="interventionZone" className="block text-gray-700 font-bold">
                                            Zone d'intervention
                                        </label>
                                        <input
                                            type="interventionZone"
                                            id="interventionZone"
                                            name="interventionZone"
                                            value={editedInterventionZone}
                                            onChange={(e) => setEditedInterventionZone(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div> */}

                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Enregistrer
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Partner;

import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Plan from "../components/Plan";
import WhatsAppButton from "../components/WhatsAppButton";
import Footer from "../components/Footer";
import bgImage from '../../src/assets/img/bg/animiertes-gif-von-online-umwandeln-de.gif';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { useNavigate } from "react-router-dom";

const Index = () => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);
    const [showGpsModal, setShowGpsModal] = useState(false);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);

    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [offers, setOffers] = useState([]);

    const handleRequestClick = () => {
        localStorage.removeItem("selectedOffer");
        setShowModal(true);
    };

    const handleFirstQuestionResponse = (response) => {
        if (response === 'yes') {
            navigate('/sheet');
        } else {
            setShowModal(false);
            setShowGpsModal(true);
        }
    };

    const handleGpsQuestionResponse = (response) => {
        if (response === 'yes') {
            navigate('/sheet');
        } else {
            setShowGpsModal(false);
            setShowThankYouMessage(true);
        }
    };

    const texts = [t('ultraFo'), t('unlimitedInternet'), t('upToFmbps')];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000);

        return () => clearInterval(interval);
    }, [texts.length]);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1023);
        };

        checkScreenSize();

        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const fetchOffers = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/offer`);
            const offersData = response.data.offers;

            setOffers(offersData);
        } catch (error) {
            console.error("Erreur lors de la récupération des offres :", error);
        }
    };

    useEffect(() => {
        fetchOffers();
    }, []);

    const navigate = useNavigate();

    // const handleNavigation = () => {
    //     localStorage.removeItem("selectedOffer");

    //     navigate("/sheet");
    // };

    return (
        <>
            <Navbar />

            <div className="bg-gray-200 flex flex-col py-5 px-6 lg:px-20">
                <div className="bg-transparent flex flex-col lg:flex-row text-white shadow-md">
                    {/* Left Section */}
                    <div className={`${isSmallScreen ? 'rounded-xl' : 'rounded-l-3xl'} lg:w-1/2 space-y-5 bg-sky-500 p-5`}>
                        <h1 className="pt-10 text-2xl lg:text-3xl font-bold leading-tight text-center">
                            {t('enjoyFiber')}
                        </h1>

                        <div className="flex justify-between items-center text-lg space-x-3 space-y-3">
                            {offers && offers.length > 0 && (
                                offers.map((offer, idx) => (
                                    <>
                                        <div className="flex flex-col items-center">
                                            <p className="text-blue-200 text-sm font-bold">{t('upTo')}</p>
                                            <p className="text-4xl font-bold text-white">{offer.speed} <span className="text-sm">Mbps</span></p>
                                        </div>
                                        {idx < offers.length - 1 && (
                                            <div className="h-12 border-l-2 border-gray-200"></div>
                                        )}
                                    </>
                                ))
                            )}
                        </div>

                        <p className="text-xs text-justify">
                            {t('textDetails')}{" "}
                            <a href="#plan" className="text-blue-200 underline">
                                {t('offerDetails')}
                            </a>
                        </p>

                        <div className="flex items-center space-x-2 text-xl font-semibold">
                            <span className="text-blue-200">+</span>
                            {/* Text rotation */}
                            <p>{texts[currentTextIndex]}</p>
                        </div>

                        <div className="pt-5">
                            <button
                                onClick={handleRequestClick}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full"
                            >
                                {t('doAdemand')}
                            </button>
                        </div>
                    </div>

                    {/* Right Section (Image) */}
                    <div className="lg:w-1/2 flex items-center justify-center lg:justify-end mt-10 lg:mt-0">
                        <img
                            src={bgImage}
                            alt="Family using tablet"
                            className={`h-full ${isSmallScreen ? 'rounded-xl' : 'rounded-r-3xl'}`}
                        />
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center">
                            {t('q1')}
                        </h2>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                onClick={() => handleFirstQuestionResponse('yes')}
                            >
                                {t('yesResponse')}
                            </button>
                            <button
                                className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                onClick={() => handleFirstQuestionResponse('no')}
                            >
                                {t('noResponse')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showGpsModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center">
                            {t('q2')}
                        </h2>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                onClick={() => handleGpsQuestionResponse('yes')}
                            >
                                {t('yesResponse')}
                            </button>
                            <button
                                className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                onClick={() => handleGpsQuestionResponse('no')}
                            >
                                {t('noResponse')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showThankYouMessage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center text-gray-700">
                            {t('msg1')}
                        </h2>
                        <p className="text-center text-gray-600">
                            {t('msg2')}
                        </p>
                        <div className="mt-4 flex justify-center">
                            <button
                                className="px-6 py-2 bg-sky-500 text-white rounded-lg hover:bg-sky-600"
                                onClick={() => setShowThankYouMessage(false)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Plan />

            <WhatsAppButton />

            <Footer />
        </>
    );
};

export default Index;
import React, { useState, useEffect } from "react";
import i18n from "../i18n";
import frFlag from '../assets/img/icons/fr.png';
import ukFlag from '../assets/img/icons/uk.png';
import '../assets/css/LanguageSelector.css';

const LanguageSelector = () => {
    const initialLanguage = localStorage.getItem('language') || 'fr';
    const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
    
        const selectElement = document.querySelector('.language-select');
        if (selectedLanguage === 'fr') {
            selectElement.style.backgroundImage = `url(${frFlag})`;
        } else if (selectedLanguage === 'en') {
            selectElement.style.backgroundImage = `url(${ukFlag})`;
        }
    }, [selectedLanguage]);

    const chooseLanguage = (e) => {
        const lang = e.target.value;
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
        localStorage.setItem('language', lang);
    };

    return (
        <div className="language-selector">
            <select 
                value={selectedLanguage} 
                onChange={chooseLanguage} 
                className="language-select"
            >
                <option value="fr" style={{ textAlign: 'left' }}>FR</option>
                <option value="en">EN</option>
            </select>
        </div>
    );
};

export default LanguageSelector;